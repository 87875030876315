export const COUNTRIES_DATA = [
    {
      country: "AE",
      latitude: 23.424076,
      longitude: 53.847818,
      name: "United Arab Emirates"
    },
    {
      country: "AO",
      latitude: -11.202692,
      longitude: 17.873887,
      name: "Angola"
    },
    {
      country: "AR",
      latitude: -38.416097,
      longitude: -63.616672,
      name: "Argentina"
    },
    {
      country: "AT",
      latitude: 47.516231,
      longitude: 14.550072,
      name: "Austria"
    },
    {
      country: "AU",
      latitude: -25.274398,
      longitude: 133.775136,
      name: "Australia"
    },
    {
      country: "BD",
      latitude: 23.684994,
      longitude: 90.356331,
      name: "Bangladesh"
    },
    {
      country: "BE",
      latitude: 50.503887,
      longitude: 4.469936,
      name: "Belgium"
    },
    {
      country: "BG",
      latitude: 42.733883,
      longitude: 25.48583,
      name: "Bulgaria"
    },
    {
      country: "BR",
      latitude: -14.235004,
      longitude: -51.92528,
      name: "Brazil"
    },
    {
      country: "CA",
      latitude: 56.130366,
      longitude: -106.346771,
      name: "Canada"
    },
    // {
    //   country: "CH",
    //   latitude: 46.818188,
    //   longitude: 8.227512,
    //   name: "Switzerland"
    // },
    // {
    //   country: "CL",
    //   latitude: -35.675147,
    //   longitude: -71.542969,
    //   name: "Chile"
    // },
    // {
    //   country: "CN",
    //   latitude: 35.86166,
    //   longitude: 104.195397,
    //   name: "China"
    // },
    // {
    //   country: "CO",
    //   latitude: 4.570868,
    //   longitude: -74.297333,
    //   name: "Colombia"
    // },
    // {
    //   country: "CR",
    //   latitude: 9.748917,
    //   longitude: -83.753428,
    //   name: "Costa Rica"
    // },
    // {
    //   country: "CZ",
    //   latitude: 49.817492,
    //   longitude: 15.472962,
    //   name: "Czech Republic"
    // },
    // {
    //   country: "DE",
    //   latitude: 51.165691,
    //   longitude: 10.451526,
    //   name: "Germany"
    // },
    // {
    //   country: "DK",
    //   latitude: 56.26392,
    //   longitude: 9.501785,
    //   name: "Denmark"
    // },
    // {
    //   country: "DO",
    //   latitude: 18.735693,
    //   longitude: -70.162651,
    //   name: "Dominican Republic"
    // },
    // {
    //   country: "DZ",
    //   latitude: 28.033886,
    //   longitude: 1.659626,
    //   name: "Algeria"
    // },
    // {
    //   country: "EC",
    //   latitude: -1.831239,
    //   longitude: -78.183406,
    //   name: "Ecuador"
    // },
    // {
    //   country: "EG",
    //   latitude: 26.820553,
    //   longitude: 30.802498,
    //   name: "Egypt"
    // },
    // {
    //   country: "ES",
    //   latitude: 40.463667,
    //   longitude: -3.74922,
    //   name: "Spain"
    // },
    // {
    //   country: "ET",
    //   latitude: 9.145,
    //   longitude: 40.489673,
    //   name: "Ethiopia"
    // },
    // {
    //   country: "FI",
    //   latitude: 61.92411,
    //   longitude: 25.748151,
    //   name: "Finland"
    // },
    // {
    //   country: "FR",
    //   latitude: 46.227638,
    //   longitude: 2.213749,
    //   name: "France"
    // },
    // {
    //   country: "GB",
    //   latitude: 55.378051,
    //   longitude: -3.435973,
    //   name: "United Kingdom"
    // },
    // {
    //   country: "GH",
    //   latitude: 7.946527,
    //   longitude: -1.023194,
    //   name: "Ghana"
    // },
    // {
    //   country: "GR",
    //   latitude: 39.074208,
    //   longitude: 21.824312,
    //   name: "Greece"
    // },
    // {
    //   country: "GT",
    //   latitude: 15.783471,
    //   longitude: -90.230759,
    //   name: "Guatemala"
    // },
    // {
    //   country: "HK",
    //   latitude: 22.396428,
    //   longitude: 114.109497,
    //   name: "Hong Kong"
    // },
    // {
    //   country: "HU",
    //   latitude: 47.162494,
    //   longitude: 19.503304,
    //   name: "Hungary"
    // },
    // {
    //   country: "ID",
    //   latitude: -0.789275,
    //   longitude: 113.921327,
    //   name: "Indonesia"
    // },
    // {
    //   country: "IE",
    //   latitude: 53.41291,
    //   longitude: -8.24389,
    //   name: "Ireland"
    // },
    // {
    //   country: "IL",
    //   latitude: 31.046051,
    //   longitude: 34.851612,
    //   name: "Israel"
    // },
    // {
    //   country: "IN",
    //   latitude: 20.593684,
    //   longitude: 78.96288,
    //   name: "India"
    // },
    // {
    //   country: "IT",
    //   latitude: 41.87194,
    //   longitude: 12.56738,
    //   name: "Italy"
    // },
    // {
    //   country: "JP",
    //   latitude: 36.204824,
    //   longitude: 138.252924,
    //   name: "Japan"
    // },
    // {
    //   country: "KE",
    //   latitude: -0.023559,
    //   longitude: 37.906193,
    //   name: "Kenya"
    // },
    // {
    //   country: "KR",
    //   latitude: 35.907757,
    //   longitude: 127.766922,
    //   name: "South Korea"
    // },
    // {
    //   country: "KW",
    //   latitude: 29.31166,
    //   longitude: 47.481766,
    //   name: "Kuwait"
    // },
    // {
    //   country: "LK",
    //   latitude: 7.873054,
    //   longitude: 80.771797,
    //   name: "Sri Lanka"
    // },
    // {
    //   country: "LT",
    //   latitude: 55.169438,
    //   longitude: 23.881275,
    //   name: "Lithuania"
    // },
    // {
    //   country: "LU",
    //   latitude: 49.815273,
    //   longitude: 6.129583,
    //   name: "Luxembourg"
    // },
    // {
    //   country: "MA",
    //   latitude: 31.791702,
    //   longitude: -7.09262,
    //   name: "Morocco"
    // },
    // {
    //   country: "MO",
    //   latitude: 22.198745,
    //   longitude: 113.543873,
    //   name: "Macau"
    // },
    // {
    //   country: "MX",
    //   latitude: 23.634501,
    //   longitude: -102.552784,
    //   name: "Mexico"
    // },
    // {
    //   country: "MY",
    //   latitude: 4.210484,
    //   longitude: 101.975766,
    //   name: "Malaysia"
    // },
    // {
    //   country: "NG",
    //   latitude: 9.081999,
    //   longitude: 8.675277,
    //   name: "Nigeria"
    // },
    // {
    //   country: "NL",
    //   latitude: 52.132633,
    //   longitude: 5.291266,
    //   name: "Netherlands"
    // },
    // {
    //   country: "NO",
    //   latitude: 60.472024,
    //   longitude: 8.468946,
    //   name: "Norway"
    // },
    // {
    //   country: "NZ",
    //   latitude: -40.900557,
    //   longitude: 174.885971,
    //   name: "New Zealand"
    // },
    // {
    //   country: "PA",
    //   latitude: 8.537981,
    //   longitude: -80.782127,
    //   name: "Panama"
    // },
    // {
    //   country: "PE",
    //   latitude: -9.189967,
    //   longitude: -75.015152,
    //   name: "Peru"
    // },
    // {
    //   country: "PH",
    //   latitude: 12.879721,
    //   longitude: 121.774017,
    //   name: "Philippines"
    // },
    // {
    //   country: "PL",
    //   latitude: 51.919438,
    //   longitude: 19.145136,
    //   name: "Poland"
    // },
    // {
    //   country: "PR",
    //   latitude: 18.220833,
    //   longitude: -66.590149,
    //   name: "Puerto Rico"
    // },
    // {
    //   country: "PT",
    //   latitude: 39.399872,
    //   longitude: -8.224454,
    //   name: "Portugal"
    // },
    // {
    //   country: "QA",
    //   latitude: 25.354826,
    //   longitude: 51.183884,
    //   name: "Qatar"
    // },
    // {
    //   country: "RO",
    //   latitude: 45.943161,
    //   longitude: 24.96676,
    //   name: "Romania"
    // },
    // {
    //   country: "RU",
    //   latitude: 61.52401,
    //   longitude: 105.318756,
    //   name: "Russia"
    // },
    // {
    //   country: "SA",
    //   latitude: 23.885942,
    //   longitude: 45.079162,
    //   name: "Saudi Arabia"
    // },
    // {
    //   country: "SE",
    //   latitude: 60.128161,
    //   longitude: 18.643501,
    //   name: "Sweden"
    // },
    // {
    //   country: "SG",
    //   latitude: 1.352083,
    //   longitude: 103.819836,
    //   name: "Singapore"
    // },
    // {
    //   country: "SI",
    //   latitude: 46.151241,
    //   longitude: 14.995463,
    //   name: "Slovenia"
    // },
    // {
    //   country: "SK",
    //   latitude: 48.669026,
    //   longitude: 19.699024,
    //   name: "Slovakia"
    // },
    // {
    //   country: "TH",
    //   latitude: 15.870032,
    //   longitude: 100.992541,
    //   name: "Thailand"
    // },
    // {
    //   country: "TR",
    //   latitude: 38.963745,
    //   longitude: 35.243322,
    //   name: "Turkey"
    // },
    // {
    //   country: "TW",
    //   latitude: 23.69781,
    //   longitude: 120.960515,
    //   name: "Taiwan"
    // },
    // {
    //   country: "TZ",
    //   latitude: -6.369028,
    //   longitude: 34.888822,
    //   name: "Tanzania"
    // },
    // {
    //   country: "UY",
    //   latitude: -32.522779,
    //   longitude: -55.765835,
    //   name: "Uruguay"
    // },
    // {
    //   country: "VN",
    //   latitude: 14.058324,
    //   longitude: 108.277199,
    //   name: "Vietnam"
    // },
    // {
    //   country: "ZA",
    //   latitude: -30.559482,
    //   longitude: 22.937506,
    //   name: "South Africa"
    // }
  ];
  