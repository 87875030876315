import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import BlogOpenImg from "../../assets/images/blogopen/headerimg2.png";
import {
  fetchRandomBlogsRequest,
  fetchBlogRequest,
} from "../../store/actions/blogs";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
import BlogOpenCard from "../../components/Blogopencard";

/* <script type="application/ld+json"> */

// </script>

const BlogOpen = ({ blogOpen, bottomBlogs }) => {
  const dispatch = useDispatch();
  const [blogSchema, setBlogSchema] = useState("");
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    if (get(blogOpen, "_id", false)) {
      let blogSchema = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": window.location.href.toString(),
        },
        headline: get(blogOpen, "title", "Title of the Blog"),
        description: get(blogOpen, "description", "Description of the Blog"),
        image: [get(blogOpen, "headerImage", BlogOpenImg)],
        author: {
          "@type": "Person",
          name: get(blogOpen, "author", "Kartik Vij"),
        },
        publisher: {
          "@type": "Organization",
          name: "Too Decimal Pvt Ltd",
          logo: {
            "@type": "ImageObject",
            url: "https://toodecimal.com/static/media/white_new%201.39e426aef4591a34cac04b272c4669ee.svg",
          },
        },
        datePublished: moment(
          `${
            get(blogOpen, "timestamp", "26th-September-2020")
              ? get(blogOpen, "timestamp", "26th-September-2020")
              : get(blogOpen, "createdAt", "26th-September-2020")
          }`
        ).format("YYYY-MM-DD"),
        dateModified: moment(
          `${get(blogOpen, "updatedAt", new Date())}`
        ).format("YYYY-MM-DD"),
      };
      setBlogSchema(JSON.stringify(blogSchema));
      console.log("Blog Schema = ", blogSchema);
    }
  }, [blogOpen]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchRandomBlogsRequest());
    }, 1000);
    dispatch(fetchBlogRequest(id));
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{get(blogOpen, "title", "Blog Title")}</title>
        <meta
          name="description"
          content={get(
            blogOpen,
            "description",
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          )}
        />
        <meta
          property="og:title"
          content={get(blogOpen, "title", "Blog Title")}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content={get(
            blogOpen,
            "description",
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          )}
        />
        <meta property="og:image" content={get(blogOpen, "headerImage", "")} />
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: blogSchema }}
        />
      </Helmet>
      <section className="Blog-open-whole-section">
        <div className="blogOpen-first-heading">
          <h1>{get(blogOpen, "title", "Title of the Blog")}</h1>
        </div>
        <div className="blog-open-semi">
          <h2>{get(blogOpen, "author", "Kartik Vij")}</h2>
          <p>
            {moment(
              `${
                get(blogOpen, "timestamp", "26th-September-2020")
                  ? get(blogOpen, "timestamp", "26th-September-2020")
                  : get(blogOpen, "createdAt", "26th-September-2020")
              }`
            ).format("ll")}
          </p>
        </div>
        <div className="heading-part-blog-img">
          <img
            src={get(blogOpen, "headerImage", { BlogOpenImg })}
            alt="Blog Header"
          />
        </div>
        <div className="Blog-open-page-text display-none-blog-text">
          <p
            dangerouslySetInnerHTML={{
              __html: `${get(blogOpen, "content", "<p>Content Not Found</p>")}`,
            }}
          ></p>
        </div>

        <section className="blog-open-recent-article blog-open-page-text">
          <h2>Recent Articles</h2>
          <div className="blog-open-recent-cards">
            {bottomBlogs?.map((Blog, index) => {
              return <BlogOpenCard key={index} blogOpen={Blog} />;
            })}
          </div>
        </section>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    blogOpen: get(state, "blogs.selectedBlog", []),
    bottomBlogs: get(state, "blogs.randomBlogs", []),
  };
};
export default connect(mapStateToProps)(BlogOpen);
