import React from "react";
import { motion } from "framer-motion";
// import {
//   BoringCompany,
//   Dodge,
//   IHeartRadio,
//   Netflix,
//   Radar2,
//   ShortyAwards,
//   WebbyAwards,
//   Naspers,
// } from "../../assets/images/About Us";

import {
  Awards1,
  Awards2,
  Awards3,
  Awards4,
  Awards5,
  Awards6,
  Awards7,
  Awards8,
  Trust1,
  Trust10,
  Trust11,
  Trust12,
  Trust2,
  Trust3,
  Trust4,
  Trust5,
  Trust6,
  Trust7,
  Trust8,
  Trust9,
} from "../../assets/About";
import { BhugoalNode } from "../../assets/Home";
import { Insta, Linkedin, Mail, Twitter } from "../../assets/images/Logos";
import { Helmet } from "react-helmet";
// import {
//   blinkCursor
// } from "../../utils/framerAnimation";
export default function AboutUs() {
  return (
    <div className="AboutUs">
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        <meta property="og:title" content={"About Us"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        {/* <meta property="og:image" content={} /> */}
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="aboutUsHead">
        <div className="left">
          <h1>
            Toodecimal/<span>Bhugoal </span>/About.md{" "}
            <span
              className="blinking-cursor"
              style={{ color: "#fff !important" }}
            >
              _
            </span>
          </h1>
          <p>
            Too Decimal Point brings to you a technology to predict the
            unpredictable factor of our lives – Weather. We provide our users
            with accurate weather forecasting services for their convenience.
            Planning their day-to-day activities are made easy and effective to
            execute with us. This company works on a single vision to eliminate
            the mishappenings that occur due to errors in weather forecast
            prediction. The team works hard and aims to provide the best
            services to you.
          </p>
          <div className="logos">
            <img draggable={false} src={Insta} alt="Instagram" />
            <img draggable={false} src={Linkedin} alt="LinkedIn" />
            <img draggable={false} src={Twitter} alt="Twitter" />
            <img draggable={false} src={Mail} alt="Email" />
          </div>
        </div>
        <div
          className="right"
          style={{
            backgroundColor: "#090b20",
          }}
        >
          <img
            draggable={false}
            src={BhugoalNode}
            alt="Bhugoal Hardware"
            style={{ maxHeight: 600, objectFit: "contain" }}
          />
        </div>
        <div className="first ImageCircles"></div>
      </div>
      <motion.div
        className="aboutsUsAwards"
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        exit={{ y: 50 }}
        transition={{ duration: 2 }}
      >
        <h1>
          Awards and <span style={{ paddingLeft: "2px" }}> Recognitions </span>
          <span className="blinking-cursor">_</span>
        </h1>
        <div className="imgGrid">
          {[
            Awards1,
            Awards2,
            Awards3,
            Awards4,
            Awards5,
            Awards6,
            Awards7,
            Awards8,
          ].map((award, index) => {
            return (
              <img
                draggable={false}
                key={`Award ${index}`}
                src={award}
                alt={`Award ${index}`}
                className="item1"
              />
            );
          })}
          {/* <img
            draggable={false}
            src={IHeartRadio}
            alt="Heart Radio Logo"
            className="item1"
          />
          <img
            draggable={false}
            src={ShortyAwards}
            alt="Shorty Awards Logo"
            className="item2"
          />
          <img
            draggable={false}
            src={WebbyAwards}
            alt="Webby Awards Logo"
            className="item3"
          />
          <img
            draggable={false}
            src={IHeartRadio}
            alt="Heart Radio Logo"
            className="item1"
          />
          <img
            draggable={false}
            src={ShortyAwards}
            alt="Shorty Awards Logo"
            className="item2"
          />
          <img
            draggable={false}
            src={WebbyAwards}
            alt="Webby Awards Logo"
            className="item3"
          /> */}
        </div>
        <div className="second ImageCircles"></div>
      </motion.div>
      <motion.div
        className="aboutUsPartners"
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        exit={{ y: 50 }}
        transition={{ duration: 3 }}
      >
        <h1>
          People who <span style={{ padding: " 0 2px" }}> trust</span> us the
          most
        </h1>
        <div className="imgGrid">
          {[
            Trust1,
            Trust2,
            Trust3,
            Trust4,
            Trust5,
            Trust6,
            Trust7,
            Trust8,
            Trust9,
            Trust10,
            Trust11,
            Trust12,
          ].map((trustImg, index) => {
            return (
              <img
                draggable={false}
                src={trustImg}
                alt={`Trust ${index}`}
                key={`Trust ${index}`}
              />
            );
          })}
          {/* <img draggable={false} src={Naspers} alt="Naspers" />
          <img draggable={false} src={Netflix} alt="Netflix" />
          <img draggable={false} src={BoringCompany} alt="Boring Company" />
          <img draggable={false} src={Dodge} alt="Dodge" />
          <img draggable={false} src={Naspers} alt="Naspers" />
          <img draggable={false} src={Netflix} alt="Netflix" />
          <img draggable={false} src={BoringCompany} alt="Boring Company" />
          <img draggable={false} src={Dodge} alt="Dodge" /> */}
        </div>
      </motion.div>
    </div>
  );
}
