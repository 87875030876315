import { call, takeLatest, put } from "redux-saga/effects";
import {
	fetchAllBlogsCategoriesService,
	fetchAllBlogsService,
	fetchFeaturedBlogsService,
	fetchRandomBlogsService,
	fetchBlogService,
} from "../../services/blogs";
import {
	fetchRandomBlogsSuccess,
	fetchRandomBlogsFailure,
	fetchAllBlogCategoriesFailure,
	fetchAllBlogCategoriesSuccess,
	fetchAllBlogsFailure,
	fetchAllBlogsSuccess,
	fetchBlogFailure,
	fetchBlogSuccess,
	fetchFeaturedBlogsSuccess,
	fetchFeaturedBlogsFailure,
} from "../actions";
import {
	FETCH_RANDOM_BLOGS,
	FETCH_ALL_BLOGS,
	FETCH_ALL_BLOG_CATEGORIES,
	FETCH_FEATURED_BLOGS,
	FETCH_BLOG,
} from "../types";
export function* fetchRandomBlogsSaga(action) {
	console.log("Inside random blogs saga");
	try {
		let res = yield call(fetchRandomBlogsService, action.payload);
		if (res.success) {
			yield put(fetchRandomBlogsSuccess(res.data));
		} else {
			yield put(fetchRandomBlogsFailure(res));
		}
	} catch (e) {
		yield put(
			fetchRandomBlogsFailure({
				success: false,
				message: e.message,
			})
		);
	}
}
export function* fetchFeaturedBlogsSaga(action) {
	console.log("Inside featured blogs saga");
	try {
		let res = yield call(fetchFeaturedBlogsService, action.payload);
		if (res.success) {
			yield put(fetchFeaturedBlogsSuccess(res.data));
		} else {
			yield put(fetchFeaturedBlogsFailure(res));
		}
	} catch (e) {
		yield put(
			fetchFeaturedBlogsFailure({
				success: false,
				message: e.message,
			})
		);
	}
}
export function* fetchAllBlogsSaga(action) {
	try {
		let res = yield call(fetchAllBlogsService, action.payload);
		if (res.success) {
			yield put(fetchAllBlogsSuccess(res.data));
		} else {
			yield put(fetchAllBlogsFailure(res));
		}
	} catch (e) {
		yield put(
			fetchAllBlogsFailure({
				success: false,
				message: e.message,
			})
		);
	}
}

export function* fetchBlogSaga(action) {
	try {
		let res = yield call(fetchBlogService, action.payload);
		if (res.success) {
			yield put(fetchBlogSuccess(res.data));
		} else {
			yield put(fetchBlogFailure(res));
		}
	} catch (e) {
		yield put(
			fetchBlogFailure({
				success: false,
				message: e.message,
			})
		);
	}
}

export function* fetchAllBlogsCategoriesSaga() {
	try {
		let res = yield call(fetchAllBlogsCategoriesService);
		if (res.success) {
			yield put(fetchAllBlogCategoriesSuccess(res.data));
		} else {
			yield put(fetchAllBlogCategoriesFailure(res));
		}
	} catch (e) {
		yield put(
			fetchAllBlogCategoriesFailure({
				success: false,
				message: e.message,
			})
		);
	}
}

const blogSaga = () => [
	takeLatest(FETCH_RANDOM_BLOGS.REQUEST, fetchRandomBlogsSaga),
	takeLatest(FETCH_ALL_BLOGS.REQUEST, fetchAllBlogsSaga),
	takeLatest(FETCH_BLOG.REQUEST, fetchBlogSaga),
	takeLatest(FETCH_ALL_BLOG_CATEGORIES.REQUEST, fetchAllBlogsCategoriesSaga),
	takeLatest(FETCH_FEATURED_BLOGS.REQUEST, fetchFeaturedBlogsSaga),
];

export default blogSaga();
