import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootReducer from "./reducers";
import { rootSaga } from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares =
  process.env.NODE_ENV !== "production"
    ? [sagaMiddleware, logger]
    : [sagaMiddleware];

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);
