import React from "react";
import { Helmet } from "react-helmet";
import {
  Circle2,
  Console,
  CurveLine,
  Line,
  LongLine,
  roundLine,
  TopCircle,
} from "../../assets/Contact Line";
import {
  bottomImage,
  topImage,
  contactbg2,
} from "../../assets/images/contact us page";
import ContactForm from "../../components/ContactForm";

export default function Contact() {
  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us</title>
        <meta
          name="description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        <meta property="og:title" content={"Contact Us"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        {/* <meta property="og:image" content={} /> */}
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <h1>
        <img draggable={false} src={TopCircle} alt=""></img>Send a message to{" "}
        <span>
          mission control <span className="blinking-cursor">_</span>
        </span>
      </h1>
      <img draggable={false} src={Line} alt="" className="line1"></img>
      <div className="contactContainer">
        <div className="contactLeft">
          <button className="showContactForm">
            {" "}
            <img
              draggable={false}
              className="console"
              src={Console}
              alt=""
            ></img>{" "}
            git show contact_form.htm
          </button>
          <div style={{ display: "flex" }}>
            <img draggable={false} src={Line} className="Line2" alt="" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                draggable={false}
                src={CurveLine}
                className="curvedLine"
                alt=""
              />
              <img draggable={false} src={Circle2} className="circle2" alt="" />
              <img
                draggable={false}
                src={LongLine}
                className="Line3Right"
                alt=""
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <img
              draggable={false}
              src={roundLine}
              alt=""
              className="roundLine1"
            />
            <p className="newFeatures">New Features are added every update</p>
          </div>
          <img draggable={false} src={Line} className="Line4" alt="" />
          <div style={{ display: "flex" }}>
            <img
              draggable={false}
              src={roundLine}
              alt=""
              className="roundLine1"
            />
            <p className="newFeatures">unlimited possibilities with bhugoal</p>
          </div>
          <div
            style={{
              borderRight: "1px solid white",
              borderLeft: "1px solid white",
              height: "400px",
              margin: " -2px 0 0 20px",
            }}
          ></div>
          <p className="GradText"></p>
        </div>
        <div className="backgroundAssets">
          <img draggable={false} src={topImage} className="image1BG" alt="" />
          <ContactForm />
          <img
            draggable={false}
            src={bottomImage}
            className="image2BG"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
