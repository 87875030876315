import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

const Privacy = ({ isLoggedIn }) => {
  const [isActive, setIsActive] = useState(true);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  return (
    <div>
      <div className="privacy-page-header">
        <h1>Privay Policy</h1>
      </div>
      <div className="privacy-policy-page-content">
        <div className="your-account-headings">
          <h1>Your Account</h1>
          <p>
            Responsibility of Users of the Website, Products, and/or Services
          </p>
          <p>Fees and Payments</p>
          <p>Use of Third Party Content and Materials</p>
          <p>Content Posted on Other Websites</p>
          <p>Copyright Infringement</p>
          <p>Trademarks</p>
          <p>Termination</p>
          <p>Changes</p>
          <p>Your Representations and Warranties</p>
          <p>Indemnification</p>
          <p>Miscellaneous</p>
          <p>A Special Note About Children</p>
        </div>
        <div className="your-account-content">
          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <div className="your-account-content-table">
            <table>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
              <tr>
                <td>ABCD</td>
                <td>Check</td>
                <td>Browser</td>
              </tr>
            </table>
          </div>
          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <div className="your-account-accordian">
            <div className="accordian-item">
              <div
                className="accordian-titile"
                onClick={() => setIsActive(!isActive)}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                {/* <div  >{isActive ? '-' : '+'}</div> */}
              </div>
              {isActive && (
                <div className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  dolor ipsum placerat varius mi facilisis. Non proin integer
                  proin turpis porttitor. Bibendum dui tristique ornare
                  ullamcorper. Urna aliquet vulputate hendrerit ornare. Commodo
                  leo elementum diam
                </div>
              )}
            </div>
            <div className="accordian-item">
              <div
                className="accordian-titile"
                onClick={() => setIsActive1(!isActive1)}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                {/* <div  >{isActive1 ? '-' : '+'}</div> */}
              </div>
              {isActive1 && (
                <div className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  dolor ipsum placerat varius mi facilisis. Non proin integer
                  proin turpis porttitor. Bibendum dui tristique ornare
                  ullamcorper. Urna aliquet vulputate hendrerit ornare. Commodo
                  leo elementum diam
                </div>
              )}
            </div>
            <div className="accordian-item">
              <div
                className="accordian-titile"
                onClick={() => setIsActive2(!isActive2)}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                {/* <div  >{isActive2 ? '-' : '+'}</div> */}
              </div>
              {isActive2 && (
                <div className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  dolor ipsum placerat varius mi facilisis. Non proin integer
                  proin turpis porttitor. Bibendum dui tristique ornare
                  ullamcorper. Urna aliquet vulputate hendrerit ornare. Commodo
                  leo elementum diam
                </div>
              )}
            </div>
            <div className="accordian-item">
              <div
                className="accordian-titile"
                onClick={() => setIsActive3(!isActive3)}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                {/* <div  >{isActive3 ? '-' : '+'}</div> */}
              </div>
              {isActive3 && (
                <div className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  dolor ipsum placerat varius mi facilisis. Non proin integer
                  proin turpis porttitor. Bibendum dui tristique ornare
                  ullamcorper. Urna aliquet vulputate hendrerit ornare. Commodo
                  leo elementum diam
                </div>
              )}
            </div>
            <div className="accordian-item">
              <div
                className="accordian-titile"
                onClick={() => setIsActive4(!isActive4)}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                {/* <div  >{isActive4 ? '-' : '+'}</div> */}
              </div>
              {isActive4 && (
                <div className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem
                  dolor ipsum placerat varius mi facilisis. Non proin integer
                  proin turpis porttitor. Bibendum dui tristique ornare
                  ullamcorper. Urna aliquet vulputate hendrerit ornare. Commodo
                  leo elementum diam
                </div>
              )}
            </div>
          </div>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>

          <h1>Your Account</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
            commodo maecenas tortor, massa. Tempus pellentesque augue turpis
            est, tellus. Sit justo, dolor pharetra neque. Lectus proin egestas
            tellus, viverra urna sit sed. Consequat, semper sapien dictum
            facilisi. Tincidunt ac facilisis venenatis sed mauris viverra. Amet
            iaculis scelerisque tristique amet arcu egestas dui nulla aenean.
          </p>
          <h2>Last Updated on : 19th October 2020</h2>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: get(state, "auth.isLoggedIn", false),
  };
};

export default connect(mapStateToProps)(Privacy);
