import React from "react";
// import "../../index.css"
import "../../index.css";
import CardImg from "../../assets/images/bloglanding/image 2 (3).png";
import { get } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { hideAnimation } from "../../utils/framerAnimation";

gsap.registerPlugin(ScrollTrigger);

const BlogAllCard = ({ blog, index }) => {
  const truncate = (str) => {
    return str.length > 100 ? str.substr(0, 100) + "..." : str;
  };
  let blogContent = document.getElementsByClassName("blogContent");
  truncate(blogContent);
  const animationcontrol = useAnimation();
  const { ref, inView } = useInView();
  if (inView) {
    animationcontrol.start({
      opacity: [0.2, 0.7, 1],
      zIndex: 2,
      y: [0, 0, 0],
      x: [0, 0, 0],
      transition: {
        ease: "easeInOut",
      },
    });
  }

  return (
    // <motion.div
    // initial={hideAnimation}
    // animate={animationcontrol}
    // duration={0.6}
    // ref={ref}
    // className="blog-open-card1">
    <div className="blog-open-card1">
      <div className="blog-open-card-img">
        <img
          draggable={false}
          src={get(blog, "headerImage", { CardImg })}
          alt=""
        />
      </div>
      <div className="blog-open-card-info">
        <h3>{get(blog, "title", "The heading of the blog will be here.")}</h3>

        <div>
          <div className="blog-open-card-info-namedate">
            <p>
              {moment(
                `${
                  get(blog, "timestamp", "26th-September-2020")
                    ? get(blog, "timestamp", "26th-September-2020")
                    : get(blog, "createdAt", "26th-September-2020")
                }`
              ).format("ll")}
            </p>
            <h6 className="authorButton">
              <p>By {get(blog, "author", " Souha.H")}</p>
            </h6>
          </div>
          <Link to={`/blogs/` + get(blog, "blogId", "id")}>Read more</Link>
        </div>
      </div>
    </div>
    // {/* </motion.div>  */}
  );
};

export default BlogAllCard;
