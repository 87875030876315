import React, { useState, useEffect } from "react";
import { BackCity2, FrontCities } from "../../assets/images/About Us";
import { Linkedin, TDLogo } from "../../assets/images/Logos";
import { useLocation, Link } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [isContact, setIsContact] = useState(false);
  useEffect(() => {
    if (location.pathname === "/contact") {
      setIsContact(true);
    } else {
      setIsContact(false);
    }
  }, [location]);
  return (
    <footer className={` ${isContact ? "BlueBG" : ""}`}>
      <div
        className={`BottomCities${
          location.pathname !== "/" && location.pathname !== "/privacy"
            ? " BlueBG"
            : " nothing"
        }`}
      >
        <img draggable={false} src={FrontCities} alt="" className="Front" />
        {/* <img src={BackCities} alt="" className="Mid" /> */}
        <svg
          width="1875"
          className="Mid"
          height="523"
          viewBox="0 0 1875 523"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 18149">
            <g id="Cities">
              <path
                id="Vector"
                d="M1872.08 454.261H0.306641V520.855H1872.08V454.261Z"
                fill="#555555"
              />
              <path
                id="Vector_2"
                d="M1048.6 157.48V87.7519H1023.2V61.2388H1009.77V38.9007H1004.94V0.696289H1003.68V38.9007H999.063V61.2388H985.419V87.7519H960.234V157.48H954.985V283.784H915.949V428.041H954.985H994.024H1053.63V157.48H1048.6Z"
                fill="#555555"
              />
              <path
                id="Vector_3"
                d="M1194.26 237.438H1071.68V239.734H1077.14V445.996H1188.8V239.734H1194.26V237.438Z"
                fill="#555555"
              />
              <path
                id="Vector_4"
                d="M634.699 288.794H525.977V460.61H634.699V288.794Z"
                fill="#555555"
              />
              <path
                id="Vector_5"
                d="M509.607 376.893H322.597V460.816H509.607V376.893Z"
                fill="#555555"
              />
              <path
                id="Vector_6"
                d="M464.061 189.63H422.923V395.475H464.061V189.63Z"
                fill="#555555"
              />
              <path
                id="Vector_7"
                d="M434.047 134.725H431.948V246.624H434.047V134.725Z"
                fill="#555555"
              />
              <path
                id="Vector_8"
                d="M440.974 143.284H438.875V255.182H440.974V143.284Z"
                fill="#555555"
              />
              <path
                id="Vector_9"
                d="M451.258 176.895H426.072V204.452H451.258V176.895Z"
                fill="#555555"
              />
              <path
                id="Vector_10"
                d="M573.831 319.482H502.259V460.61H573.831V319.482Z"
                fill="#555555"
              />
              <path
                id="Vector_11"
                d="M787.141 109.571H652.002V460.643H787.141V109.571Z"
                fill="#555555"
              />
              <path
                id="Vector_12"
                d="M773.246 98.082H665.893V156.807H773.246V98.082Z"
                fill="#555555"
              />
              <path
                id="Vector_13"
                d="M797.241 117.231H641.897V121.061H797.241V117.231Z"
                fill="#555555"
              />
              <path
                id="Vector_14"
                d="M691.369 314.055H628.192V460.82H691.369V314.055Z"
                fill="#555555"
              />
              <path
                id="Vector_15"
                d="M248.087 308.418H175.466V460.61H248.087V308.418Z"
                fill="#555555"
              />
              <path
                id="Vector_16"
                d="M343.586 267.083H198.973V460.816H343.586V267.083Z"
                fill="#555555"
              />
              <path
                id="Vector_17"
                d="M347.153 259.567H190.368V267.082H347.153V259.567Z"
                fill="#555555"
              />
              <path
                id="Vector_18"
                d="M268.865 239.525H205.27V278.773H268.865V239.525Z"
                fill="#555555"
              />
              <path
                id="Vector_19"
                d="M300.349 239.525H289.645V263.324H300.349V239.525Z"
                fill="#555555"
              />
              <path
                id="Vector_20"
                d="M322.596 239.525H310.843V263.324H322.596V239.525Z"
                fill="#555555"
              />
              <path
                id="Vector_21"
                d="M211.776 401.737H136.007V460.61H211.776V401.737Z"
                fill="#555555"
              />
              <path
                id="Vector_22"
                d="M143.143 376.893H107.252V460.816H143.143V376.893Z"
                fill="#555555"
              />
              <path
                id="Vector_23"
                d="M125.303 431.172H64.0156V460.61H125.303V431.172Z"
                fill="#555555"
              />
              <path
                id="Vector_24"
                d="M1528.61 395.475H773.436V460.816H1528.61V395.475Z"
                fill="#555555"
              />
              <path
                id="Vector_25"
                d="M1607.11 290.673H1490.41V460.61H1607.11V290.673Z"
                fill="#555555"
              />
              <path
                id="Vector_26"
                d="M1706.59 356.853H1589.89V459.774H1706.59V356.853Z"
                fill="#555555"
              />
              <path
                id="Vector_27"
                d="M1203.08 341.612H1137.59V428.041H1203.08V341.612Z"
                fill="#555555"
              />
              <path
                id="Vector_28"
                d="M1645.09 124.891H1562.6V456.412H1645.09V124.891Z"
                fill="#555555"
              />
              <path
                id="Vector_29"
                d="M1767.04 460.61H1658.11V267.083H1740.59L1767.04 460.61Z"
                fill="#555555"
              />
              <path
                id="Vector_30"
                d="M1814.68 416.558H1719.4V460.816H1814.68V416.558Z"
                fill="#555555"
              />
              <path
                id="Vector_31"
                d="M1612.77 59.7773H1609.83V149.965H1612.77V59.7773Z"
                fill="#555555"
              />
              <path
                id="Vector_32"
                d="M1622.43 79.1924H1619.49V169.38H1622.43V79.1924Z"
                fill="#555555"
              />
              <path
                id="Vector_33"
                d="M1603.96 91.5088H1601.02V181.696H1603.96V91.5088Z"
                fill="#555555"
              />
              <path
                id="Vector_34"
                d="M1701.55 251.425H1662.52V281.905H1701.55V251.425Z"
                fill="#555555"
              />
              <path
                id="Vector_35"
                d="M1697.15 240.569H1666.71V257.688H1697.15V240.569Z"
                fill="#555555"
              />
              <path
                id="Vector_36"
                d="M1724.43 260.401H1716.88V272.719H1724.43V260.401Z"
                fill="#555555"
              />
              <path
                id="Vector_37"
                d="M1737.66 249.128H1726.95V272.719H1737.66V249.128Z"
                fill="#555555"
              />
              <g id="whole-wheel">
                <path
                  id="Vector_38"
                  d="M1264 107C1264 111.971 1259.97 116 1255 116C1250.03 116 1246 111.971 1246 107C1246 102.029 1250.03 98 1255 98C1259.97 98 1264 102.029 1264 107Z"
                  fill="#555455"
                />
                <path
                  id="Vector_39"
                  d="M1264 297C1264 292.029 1259.97 288 1255 288C1250.03 288 1246 292.029 1246 297C1246 301.971 1250.03 306 1255 306C1259.97 306 1264 301.971 1264 297Z"
                  fill="#555455"
                />
                <path
                  id="Vector_40"
                  d="M1235 148C1235 152.971 1230.97 157 1226 157C1221.03 157 1217 152.971 1217 148C1217 143.029 1221.03 139 1226 139C1230.97 139 1235 143.029 1235 148Z"
                  fill="#555455"
                />
                <path
                  id="Vector_41"
                  d="M1235 256C1235 251.029 1230.97 247 1226 247C1221.03 247 1217 251.029 1217 256C1217 260.971 1221.03 265 1226 265C1230.97 265 1235 260.971 1235 256Z"
                  fill="#555455"
                />
                <path
                  id="Vector_42"
                  d="M1310 78C1310 82.9706 1305.97 87 1301 87C1296.03 87 1292 82.9706 1292 78C1292 73.0294 1296.03 69 1301 69C1305.97 69 1310 73.0294 1310 78Z"
                  fill="#555455"
                />
                <path
                  id="Vector_43"
                  d="M1310 326C1310 321.029 1305.97 317 1301 317C1296.03 317 1292 321.029 1292 326C1292 330.971 1296.03 335 1301 335C1305.97 335 1310 330.971 1310 326Z"
                  fill="#555455"
                />
                <path
                  id="Vector_44"
                  d="M1358 69C1358 73.9706 1353.97 78 1349 78C1344.03 78 1340 73.9706 1340 69C1340 64.0294 1344.03 60 1349 60C1353.97 60 1358 64.0294 1358 69Z"
                  fill="#555455"
                />
                <path
                  id="Vector_45"
                  d="M1358 335C1358 330.029 1353.97 326 1349 326C1344.03 326 1340 330.029 1340 335C1340 339.971 1344.03 344 1349 344C1353.97 344 1358 339.971 1358 335Z"
                  fill="#555455"
                />
                <path
                  id="Vector_46"
                  d="M1403 81C1403 85.9706 1398.97 90 1394 90C1389.03 90 1385 85.9706 1385 81C1385 76.0294 1389.03 72 1394 72C1398.97 72 1403 76.0294 1403 81Z"
                  fill="#555455"
                />
                <path
                  id="Vector_47"
                  d="M1403 323C1403 318.029 1398.97 314 1394 314C1389.03 314 1385 318.029 1385 323C1385 327.971 1389.03 332 1394 332C1398.97 332 1403 327.971 1403 323Z"
                  fill="#555455"
                />
                <path
                  id="Vector_48"
                  d="M1449 113C1449 117.971 1444.97 122 1440 122C1435.03 122 1431 117.971 1431 113C1431 108.029 1435.03 104 1440 104C1444.97 104 1449 108.029 1449 113Z"
                  fill="#555455"
                />
                <path
                  id="Vector_49"
                  d="M1449 291C1449 286.029 1444.97 282 1440 282C1435.03 282 1431 286.029 1431 291C1431 295.971 1435.03 300 1440 300C1444.97 300 1449 295.971 1449 291Z"
                  fill="#555455"
                />
                <path
                  id="Vector_50"
                  d="M1476 157C1476 161.971 1471.97 166 1467 166C1462.03 166 1458 161.971 1458 157C1458 152.029 1462.03 148 1467 148C1471.97 148 1476 152.029 1476 157Z"
                  fill="#555455"
                />
                <path
                  id="Vector_51"
                  d="M1476 247C1476 242.029 1471.97 238 1467 238C1462.03 238 1458 242.029 1458 247C1458 251.971 1462.03 256 1467 256C1471.97 256 1476 251.971 1476 247Z"
                  fill="#555455"
                />
                <path
                  id="Vector_52"
                  d="M1486 202C1486 206.971 1481.97 211 1477 211C1472.03 211 1468 206.971 1468 202C1468 197.029 1472.03 193 1477 193C1481.97 193 1486 197.029 1486 202Z"
                  fill="#555455"
                />
                <path
                  id="Vector_53"
                  d="M1221 202C1221 206.971 1216.97 211 1212 211C1207.03 211 1203 206.971 1203 202C1203 197.029 1207.03 193 1212 193C1216.97 193 1221 197.029 1221 202Z"
                  fill="#555455"
                />
                <path
                  id="Vector_54"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1344.5 313C1405.53 313 1455 263.527 1455 202.5C1455 141.472 1405.53 92 1344.5 92C1283.47 92 1234 141.472 1234 202.5C1234 263.527 1283.47 313 1344.5 313ZM1344.5 314C1406.08 314 1456 264.08 1456 202.5C1456 140.92 1406.08 91 1344.5 91C1282.92 91 1233 140.92 1233 202.5C1233 264.08 1282.92 314 1344.5 314Z"
                  fill="#565556"
                />
                <path
                  id="Vector_55"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1344.5 279C1386.75 279 1421 244.75 1421 202.5C1421 160.25 1386.75 126 1344.5 126C1302.25 126 1268 160.25 1268 202.5C1268 244.75 1302.25 279 1344.5 279ZM1344.5 280C1387.3 280 1422 245.302 1422 202.5C1422 159.698 1387.3 125 1344.5 125C1301.7 125 1267 159.698 1267 202.5C1267 245.302 1301.7 280 1344.5 280Z"
                  fill="#565556"
                />
                <path
                  id="Vector_56"
                  d="M1351 202.5C1351 198.91 1348.09 196 1344.5 196C1340.91 196 1338 198.91 1338 202.5C1338 206.09 1340.91 209 1344.5 209C1348.09 209 1351 206.09 1351 202.5Z"
                  fill="#565556"
                />
                <path
                  id="Vector_57"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1344.5 197C1347.54 197 1350 199.462 1350 202.5C1350 205.538 1347.54 208 1344.5 208C1341.46 208 1339 205.538 1339 202.5C1339 199.462 1341.46 197 1344.5 197ZM1344.5 196C1348.09 196 1351 198.91 1351 202.5C1351 206.09 1348.09 209 1344.5 209C1340.91 209 1338 206.09 1338 202.5C1338 198.91 1340.91 196 1344.5 196Z"
                  fill="#565556"
                />
                <path
                  id="Vector_58"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1349 79L1345 196L1344 195.966L1348 78.9658L1349 79Z"
                  fill="#555455"
                />
                <path
                  id="Vector_59"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1349 325L1345 208L1344 208.034L1348 325.034L1349 325Z"
                  fill="#555455"
                />
                <path
                  id="Vector_60"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1391 90.9998L1347 196L1346.08 195.614L1390.08 90.6133L1391 90.9998Z"
                  fill="#555455"
                />
                <path
                  id="Vector_61"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1391 313L1347 208L1346.08 208.386L1390.08 313.386L1391 313Z"
                  fill="#555455"
                />
                <path
                  id="Vector_62"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1434 119L1349 199L1348.32 198.272L1433.32 118.271L1434 119Z"
                  fill="#555455"
                />
                <path
                  id="Vector_63"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1434 285L1349 205L1348.32 205.728L1433.32 285.728L1434 285Z"
                  fill="#555455"
                />
                <path
                  id="Vector_64"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1304 86L1343 196L1342.06 196.334L1303.06 86.3342L1304 86Z"
                  fill="#555455"
                />
                <path
                  id="Vector_65"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1304 318L1343 208L1342.06 207.666L1303.06 317.666L1304 318Z"
                  fill="#555455"
                />
                <path
                  id="Vector_66"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1261 113L1341 198L1340.27 198.685L1260.27 113.685L1261 113Z"
                  fill="#555455"
                />
                <path
                  id="Vector_67"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1261 291L1341 206L1340.27 205.315L1260.27 290.315L1261 291Z"
                  fill="#555455"
                />
                <path
                  id="Vector_68"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1234 151L1339 200L1338.58 200.906L1233.58 151.906L1234 151Z"
                  fill="#555455"
                />
                <path
                  id="Vector_69"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1234 253L1339 204L1338.58 203.094L1233.58 252.094L1234 253Z"
                  fill="#555455"
                />
                <path
                  id="Vector_70"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1220.98 201.5H1339.02V202.5H1220.98V201.5Z"
                  fill="#555455"
                />
                <path
                  id="Vector_71"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1349.98 201.5H1468.02V202.5H1349.98V201.5Z"
                  fill="#555455"
                />
                <path
                  id="Vector_72"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1458 161L1350 200L1349.66 199.059L1457.66 160.059L1458 161Z"
                  fill="#555455"
                />
                <path
                  id="Vector_73"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1458 243L1350 204L1349.66 204.941L1457.66 243.941L1458 243Z"
                  fill="#555455"
                />
              </g>
              <line
                id="Line 35"
                x1="1341.34"
                y1="205.682"
                x2="1240.34"
                y2="403.682"
                stroke="#555555"
                strokeWidth="3"
              />
              <line
                id="Line 36"
                x1="1348.33"
                y1="205.303"
                x2="1451.68"
                y2="402.086"
                stroke="#555555"
                strokeWidth="3"
              />
            </g>
          </g>
        </svg>
        <img src={BackCity2} alt="" className="Back" />
      </div>
      <div className="data">
        <div className="footer Left">
          <img src={TDLogo} alt="footerLogo"></img>
          {/* <span className="margin-botm-span">
            Some random headings will come here. And some other random things
          </span> */}
          {/* <span>Address of The Shop</span> */}
          <a href="tel:+917009110907">+917009110907</a>
          <br></br>
          <a href="mailto:toodecimal@gmail.com">toodecimal@gmail.com</a>
          <br></br>

          <div className="imgDiv">
            <a
              href="https://www.linkedin.com/company/toodecimal/"
              rel="noreferrer"
              target="_blank"
            >
              <img draggable={false} src={Linkedin} alt="LinkedIn" />
            </a>
            {/* <img draggable={false} src={Insta} alt="Instagram" /> */}
          </div>
        </div>
        <div className="footer Right">
          <ul>
            <div>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <Link to="/about">About Us</Link>
            </div>
            <div>
              <li>
                <Link to="/contact">Get in Touch</Link>
              </li>
              {/* <li></li> */}
            </div>
          </ul>
          <p className="bottomText">
            &#169; Copyright 2022 Too Decimal | All Rights Reserved
            {/* Fueled by{" "}
            <a
              href="https://www.saaspect.com?utm_source=too-decimal-website&utm_medium=website&utm_campaign=website-development"
              rel="noreferrer"
              target="_blank"
            >
              Saaspect
            </a> */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
