import {
	SET_TITLE_FILTER,
	SET_SORT_TYPE_FILTER,
	SET_SEARCH_OPTION,
	SET_SORT_FILTER,
	SET_FROM_DATE,
	SET_TO_DATE,
	SET_TITLE, 
	SET_DATE_FILTER_TOGGLE,
	SET_AUTHOR,
	SET_AUTHOR_FILTER_TOGGLE, 
} from "../types";

const filtersInitialState = {
	sortBy: "createdAt",
	sortType: -1,
	searchOption: "",
	isTitleFilter: false, 
	isDateFilter: false, 
	isAuthorFilter: false, 
	toDate: null,
	fromDate: null,
	title: "", 
	author: "", 
};

const filtersReducer = (state = filtersInitialState, action) => {
	switch (action.type) {
		case SET_TITLE_FILTER:
			return {
				...state,
				isTitleFilter: action.payload,
			};
		case SET_SEARCH_OPTION:
			return {
				...state,
				searchOption: action.payload,
			};
		case SET_SORT_FILTER:
			return {
				...state,
				sortBy: action.payload,
			};
		case SET_SORT_TYPE_FILTER:
			return {
				...state,
				sortType: action.payload,
			};
		case SET_TO_DATE:
			return {
				...state,
				toDate: action.payload,
			};
		case SET_FROM_DATE:
			return {
				...state,
				fromDate: action.payload,
			};
		case SET_TITLE:
			return {
				...state,
				title: action.payload,
			};
		 
		 
		case SET_DATE_FILTER_TOGGLE:
			return {
				...state,
				isDateFilter: action.payload,
			};

		case SET_AUTHOR:
			return {
				...state,
				author: action.payload,
			};
		case SET_AUTHOR_FILTER_TOGGLE:
			return {
				...state,
				isAuthorFilter: action.payload,
			};
		
		default:
			return state;
	}
};

export default filtersReducer;
