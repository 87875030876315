import axios from "axios";
import { apiURL } from "../constants";
import { get } from "lodash";
export const fetchFeaturedBlogsService = async (data) => {
  try {
    console.log("Inside fetch all blog service data = ");
    let featured = get(data, "featured", false);
    let URL = apiURL + `api/v1/Blog/getActive?featured=${featured}`;
    let config = {
			params: {
        searchOption: data.searchOption
			},
		};
    console.log("API URL endpoint = ", URL);
    let res = await axios.get(URL, config);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: "",
      };
    } else {
      return {
        success: false,
        data: {},
        message: res.data,
      };
    }
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const fetchAllBlogsService = async (data) => {
  try {
    console.log("Inside fetch all blog service data = ");
    // let featured = get(data, "featured", false);
    let URL = apiURL + `api/v1/Blog/getActive`;
    console.log("API URL endpoint = ", URL);
    let config = {
			params: {
				page: data.page,
				perPage: data.perPage,
				sortType: data.sortType,
				sortBy:data.sortBy,
        searchOption: data.searchOption,
			},
		};

		let res = await axios.get(URL, config);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: "",
      };
    } else {
      return {
        success: false,
        data: {},
        message: res.data,
      };
    }
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const fetchRandomBlogsService = async (data) => {
  try {
    let URL = apiURL + `api/v1/Blog/randomBlog`;
    // console.log("API URL endpoint = ", URL);
    let res = await axios.get(URL);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: "",
      };
    } else {
      return {
        success: false,
        data: {},
        message: res.data,
      };
    }
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const fetchBlogService = async (id) => {
  try {
    console.log("Inside fetch all blog service data id = ", id);
    let URL = apiURL + "api/v1/blog/getOne/" + id;
    console.log("API URL endpoint = ", URL);
    let res = await axios.get(URL);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: "",
      };
    } else {
      return {
        success: false,
        data: {},
        message: res.data,
      };
    }
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const fetchAllBlogsCategoriesService = async () => {
  try {
    console.log("Inside fetch all blog category service id = ");
    let URL = apiURL + "api/v1/blogCategory";
    console.log("API URL endpoint = ", URL);
    let res = await axios.get(URL);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
        message: "",
      };
    } else {
      return {
        success: false,
        data: {},
        message: res.data,
      };
    }
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
