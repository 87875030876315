import {
  FETCH_ALL_BLOGS,
  FETCH_ALL_BLOG_CATEGORIES,
  FETCH_BLOG,
  FETCH_RANDOM_BLOGS,
  FETCH_FEATURED_BLOGS
} from "../types";

export const fetchFeaturedBlogsRequest = (payload) => ({
  type: FETCH_FEATURED_BLOGS.REQUEST,
  payload,
});

export const fetchFeaturedBlogsSuccess = (payload) => ({
  type: FETCH_FEATURED_BLOGS.SUCCESS,
  payload,
});
export const fetchFeaturedBlogsFailure = (payload) => ({
  type: FETCH_FEATURED_BLOGS.FAILURE,
  payload,
})
export const fetchRandomBlogsRequest = (payload) => ({
  type: FETCH_RANDOM_BLOGS.REQUEST,
  payload,
});

export const fetchRandomBlogsSuccess = (payload) => ({
  type: FETCH_RANDOM_BLOGS.SUCCESS,
  payload,
});

export const fetchRandomBlogsFailure = (payload) => ({
  type: FETCH_RANDOM_BLOGS.FAILURE,
  payload,
});
export const fetchAllBlogsRequest = (payload) => ({
  type: FETCH_ALL_BLOGS.REQUEST,
  payload,
});

export const fetchAllBlogsSuccess = (payload) => ({
  type: FETCH_ALL_BLOGS.SUCCESS,
  payload,
});

export const fetchAllBlogsFailure = (payload) => ({
  type: FETCH_ALL_BLOGS.FAILURE,
  payload,
});

export const fetchBlogRequest = (payload) => ({
  type: FETCH_BLOG.REQUEST,
  payload,
});

export const fetchBlogSuccess = (payload) => ({
  type: FETCH_BLOG.SUCCESS,
  payload,
});

export const fetchBlogFailure = (payload) => ({
  type: FETCH_BLOG.FAILURE,
  payload,
});

export const fetchAllBlogCategoriesRequest = (payload) => ({
  type: FETCH_ALL_BLOG_CATEGORIES.REQUEST,
  payload,
});

export const fetchAllBlogCategoriesSuccess = (payload) => ({
  type: FETCH_ALL_BLOG_CATEGORIES.SUCCESS,
  payload,
});

export const fetchAllBlogCategoriesFailure = (payload) => ({
  type: FETCH_ALL_BLOG_CATEGORIES.FAILURE,
  payload,
});
