import React, { useState } from "react";
import axios from "axios";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { apiURL } from "../../constants";

const JoinWaitlist = (props) => {
  let formSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required(),
  });
  axios.defaults.baseURL = apiURL;
  // console.log(props);
  // const contactForm = useSelector((state)=>state)

  const [submission, setSubmission] = useState(false);
  //false for not submitted and true for submitted!
  const { status, setStatus } = props;
  // const [status,setStatus] = useState(false);

  // console.log(status);

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    let formData = {
      email: values.email,
    };
    setSubmission(true);
    axios
      .post("/api/v1/leadForm/", formData)
      .then((response) => {
        // toast.success("Form Submitted Successfully", {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
        setTimeout(() => {
          setStatus(true);
        }, 1000);
      })
      .catch((err) => {
        // toast.error("Error while submitting the form.", {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
        setStatus(false);
      });
    setTimeout(() => {
      setSubmission(false);
      resetForm();
    }, 1000);
  };

  return (
    <div className="join-waitlist-card">
      <ToastContainer />
      <h1>
        Get More than <span>“Just”</span> weather
      </h1>
      <p>
        <ul className="join-waitlist-card-list">
          <li className="join-waitlist-card-list-item">
            <span>
              Our services provide timely and continuous updates on the climatic
              changes in your locality along with accurate data insights
            </span>
          </li>
          <li className="join-waitlist-card-list-item">
            <span>
              Per se your industry, our app would also suggest what measures one
              can take according to different weather changes.
            </span>
          </li>
          <li className="join-waitlist-card-list-item">
            <span>
              Precise and detailed predictions eliminate the error factor and
              gives one time to prepare for the upcoming weather.
            </span>
          </li>
          <li className="join-waitlist-card-list-item">
            <span>
              Our services also includes providing information regarding the
              best preventive and precautionary measures in essence to your
              business
            </span>
          </li>
        </ul>
      </p>
      <h2>Join the waitlist to be one of the first ones</h2>
      {!status ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={formSchema}
          onSubmit={submitHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="join-email-input">
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <div className="input-feedback text-danger">
                    {errors.email}
                  </div>
                )}
                <div className="transmit-btn">
                  {submission ? (
                    <button
                      type="submit"
                      disabled={true}
                      className="transmiting-data"
                    >
                      Transmiting Data
                      <span className="dot-pulse"></span>
                      {/* <span className="dot-1">.</span>
                    <span className="dot-2">.</span>
                    <span className="dot-3">.</span> */}
                    </button>
                  ) : (
                    <button type="submit" disabled={false}>
                      Transmit Data
                    </button>
                  )}
                  {/* <button type="submit" disabled={submission}>{submission?('Transmiting Data...'):('Transmit Data')}</button> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="form-submit-success-div">
          <h2>Form Submitted Successfully!</h2>
        </div>
      )}
    </div>
  );
};

export default JoinWaitlist;
