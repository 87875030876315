import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";
import Magnify from "../../assets/images/bloglanding/magnifyingglass.svg";
import { fetchAllBlogsRequest } from "../../store/actions/blogs";
import BlogAllCard from "../../components/BlogAllCard";
import { debounce } from "lodash";
import {
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogAll = ({ allBlogs, sortBy, sortType, pages }) => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const [perPage, setPerPage] = useState(8);
  const pageNumbers = [];
  const [searchParams, setSearchParams] = useSearchParams();

  let param = searchParams.get("search");

  const [searchTerm, setSearchTerm] = useState(param);
  const [selectedPage, setSelectedPage] = useState(1);

  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn(inputValue) {
    console.log(
      "Handle debounce function called with input val = ",
      inputValue
    );
    if (inputValue === "") {
      setSearchParams(searchParams.delete("search"));
    } else setSearchParams(createSearchParams({ search: inputValue }));
  }

  useEffect(() => {
    console.log("Search Params = ", searchParams.get("search"));
    let querySearchText = searchParams.get("search");
    if (querySearchText) {
      setSearchTerm(searchParams.get("search"));
    } else if (querySearchText === "") {
      setSearchParams(searchParams.delete("search"));
    }

    return () => {
      let querySearchText = searchParams.get("search");
      if (querySearchText) {
        setSearchParams(searchParams.delete("search"));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      fetchAllBlogsRequest({
        perPage: perPage,
        page: selectedPage,
        searchOption: searchTerm,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, selectedPage, dispatch, param]);

  const changePage = (page) => {
    setSelectedPage(page);
  };

  const changePerPage = (perPage) => {
    setSelectedPage(1);
    setPerPage(perPage);
  };
  const [show, setShow] = useState(false);
  const displaySorting = () => {
    setShow(!show);
  };

  return (
    <section className="blog-all-post-page">
      <Helmet>
        <title>All Blogs</title>
        <meta
          name="description"
          content={
            "Read the latest published blogs written by developers at Too Decimal."
          }
        />
        <meta property="og:title" content={"All Blogs"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            "Read the latest published blogs written by developers at Too Decimal."
          }
        />
        {/* <meta property="og:image" content={} /> */}
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="popukar-now-section-search blog-all-navbar">
        <h1 className="blog-all-page-search-text">Search Topics</h1>
        <div className="popukar-now-section-search-input blog-all-input">
          <img
            src={Magnify}
            alt=""
            onClick={() =>
              dispatch(fetchAllBlogsRequest({ searchOption: searchTerm }))
            }
          />
          <input
            id="search"
            type="text"
            placeholder="Search Articles"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debounceFn(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                dispatch(fetchAllBlogsRequest({ searchOption: searchTerm }));
              }
            }}
          />
        </div>
        <div
          className="first ImageCircles"
          style={{ top: "0", bottom: "0" }}
        ></div>
      </div>
      <div className="blog-landing-page-recent blog-all-post-change-back">
        <div className="sort-by-posted">
          <h1>All Posts </h1>

          <div className="filter-checkbox-group">
            <label style={{ cursor: "pointer" }} onClick={displaySorting}>
              Sort By
            </label>
            <div
              id="SortingOptions"
              style={{ display: `${show ? "flex" : "none"}` }}
            >
              <input
                type="submit"
                value="Oldest to Newest"
                name="Oldest to Newest"
                id="OTN"
                className={`selected ${
                  sortBy === "createdAt" && sortType === 1 ? "active" : ""
                }`}
                onClick={() => {
                  dispatch(
                    fetchAllBlogsRequest({ sortBy: "createdAt", sortType: 1 })
                  );
                  displaySorting();
                }}
              />
              <input
                type="submit"
                value="Newest to Oldest"
                name="Newest to Oldest"
                id="NTO"
                className={`selected ${
                  sortBy === "createdAt" && sortType === 1 ? "active" : ""
                }`}
                onClick={() => {
                  dispatch(
                    fetchAllBlogsRequest({
                      sortBy: "createdAt",
                      sortType: -1,
                    })
                  );
                  displaySorting();
                }}
              />
            </div>
          </div>
          <div className="wrapper2">
            <p>Show</p>
            <select
              value={perPage}
              onChange={(e) => changePerPage(e.target.value)}
            >
              <option value="8">8</option>
              <option value="12">12</option>
              <option value="16">16</option>
            </select>
          </div>
        </div>

        <div className="blog-open-recent-cards">
          {allBlogs?.map((blogData, index) => (
            <BlogAllCard key={index} blog={blogData} />
          ))}
        </div>
      </div>
      <div className="pagination">
        {/* <img src={LeftArrow} alt="" /> */}
        <ul className="pagination-list">
          {pageNumbers.map((page) => (
            <li
              key={page}
              onClick={() => changePage(page)}
              className={selectedPage === page ? "selected-page" : null}
            >
              {page}
            </li>
          ))}
        </ul>
        {/* <img src={RightArrow} alt="" /> */}
      </div>
      {perPage > 8 ? <div className="second ImageCircles"></div> : ""}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: get(state, "auth.isLoggedIn", false),
    allBlogs: get(state, "blogs.allBlogs", []),
    pages: get(state, "blogs.pages", 1),
  };
};

export default connect(mapStateToProps)(BlogAll);
