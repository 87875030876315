import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { get } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import search from "../../assets/images/bloglanding/magnifyingglass.svg";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { debounce } from "lodash";

import {
  fetchAllBlogsRequest,
  fetchFeaturedBlogsRequest,
  // fetchFeaturedBlogsRequest,
} from "../../store/actions/blogs";
import BlogOpenCard from "../../components/Blogopencard";
import FeatureCardAlternate from "../../components/FeautreCard/FeautureCardAlternate";
import FeatureCard from "../../components/FeautreCard/FeautureCard";
import { Helmet } from "react-helmet";

const Bloglanding = ({ allBlogs, bottomBlogs }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchAllBlogsRequest({}));
    setTimeout(() => {
      dispatch(fetchFeaturedBlogsRequest({ featured: true }));
    }, 1000);
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn(inputValue) {
    // console.log(
    //   "Handle debounce function called with input val = ",
    //   inputValue
    // );
    if (inputValue === "") {
      setSearchParams(searchParams.delete("search"));
    } else {
      setSearchParams(createSearchParams({ search: inputValue }));
      navigate(`/all-blogs?search=${inputValue}`);
    }
  }

  useEffect(() => {
    console.log("Search Params = ", searchParams.get("search"));
    let querySearchText = searchParams.get("search");
    if (querySearchText) {
      setSearchTerm(searchParams.get("search"));
    } else if (querySearchText === "") {
      setSearchParams(searchParams.delete("search"));
    }
  }, []);

  return (
    <section className="blog-all-post-page">
      <Helmet>
        <title>Featured Blogs</title>
        <meta
          name="description"
          content={
            "Read the latest featured blogs written by developers at Too Decimal."
          }
        />
        <meta property="og:title" content={"Featured Blogs"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            "Read the latest featured blogs written by developers at Too Decimal."
          }
        />
        {/* <meta property="og:image" content={} /> */}
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <section className="blog-landing-page-header">
        <div className="blog-landing-page-header-text"></div>
        <div className="blog-landing-page-header-input">
          <input
            type="text"
            placeholder="Search"
            onChange={(event) => {
              setSearchTerm(event.target.value);
              debounceFn(event.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                dispatch(fetchAllBlogsRequest({ searchOption: searchTerm }));
                dispatch(
                  fetchFeaturedBlogsRequest({
                    featured: true,
                    searchOption: searchTerm,
                  })
                );
              }
            }}
          />
          <img
            draggable={false}
            src={search}
            alt="Search Icon"
            onClick={() => {
              dispatch(fetchAllBlogsRequest({ searchOption: searchTerm }));
              dispatch(
                fetchFeaturedBlogsRequest({
                  featured: true,
                  searchOption: searchTerm,
                })
              );
            }}
          />
        </div>

        <section className="Featured-articles-blogopen Featured-articles-blogopen-blog-all">
          <div className="first Blog-svg"></div>

          <h1>Featured Articles</h1>
          <div className="feautred-articles-blogopen-cards feautred-articles-blogopen-cards-landing">
            {bottomBlogs.result?.map((Blog, index) => {
              if (index >= 2) return null;
              return index % 2 === 0 ? (
                <FeatureCard key={index} featuredData={Blog} />
              ) : (
                <FeatureCardAlternate key={index} featuredData={Blog} />
              );
            })}
          </div>
        </section>

        <div className="second Blog-svg"></div>

        {get(allBlogs, "length", 0) > 0 && (
          <div className="blog-landing-page-recent">
            <h1 style={{ marginLeft: "5vw" }}>Recent Posts</h1>
            <section className="blog-open-recent-article blog-open-recent-article-bloglanding">
              {/* <h2>Recent Articles</h2> */}

              <div className="blog-open-recent-cards">
                {allBlogs?.map((Data, index) => {
                  return <BlogOpenCard key={index} blogOpen={Data} />;
                })}
              </div>
              {get(allBlogs, "length", 0) > 3 && (
                <div className="view-all-text-blog-landing">
                  <Link to="/all-blogs" className="linkToAllBlogs">
                    <p>View All</p>
                  </Link>
                </div>
              )}
            </section>
          </div>
        )}
        {/* <div className="blog-landing-feature-section">
          <h1>Featured Post</h1>
          <div className="feautred-articles-blogopen-cards feautred-articles-blogopen-cards-landing">
            {bottomBlogs.result?.map((Blog, index) => {
              return index % 2 === 0 ? (
                <FeatureCard key={index} featuredData={Blog} />
              ) : (
                <FeatureCardAlternate key={index} featuredData={Blog} />
              );
            })}
          </div>
        </div> */}
      </section>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: get(state, "auth.isLoggedIn", false),
    allBlogs: get(state, "blogs.allBlogs", []),
    bottomBlogs: get(state, "blogs.featuredBlogs", []),
  };
};

export default connect(mapStateToProps)(Bloglanding);
