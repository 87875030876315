import React, { useRef, useState } from "react";
import { TDLogo, Toggler } from "../../assets/images/Logos";
import { NavLink, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  revealAnimation,
  hideAnimation,
  blinkText,
  blinkLoop,
} from "../../utils/framerAnimation";
const Navbar = () => {
  const collapseRef = useRef();
  const [active, setActive] = useState(null);
  const toggling = () => {
    setActive(!active);
  };
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light${
        active ? " mobileWidth" : ""
      }`}
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <motion.img
            draggable={false}
            initial={hideAnimation}
            animate={revealAnimation}
            src={TDLogo}
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref={collapseRef}
        >
          <span>
            <img
              draggable={false}
              src={Toggler}
              alt=""
              className="img-fluid toggler"
              onClick={toggling}
            />
          </span>
        </button>
        <ul className={`navbar-nav ${active ? "active" : ""}`}>
          <li className="nav-item">
            <NavLink
              exact="true"
              to="/"
              className="toodecimal-nav-link"
              aria-current="page"
              activeclassname="toodecimal-nav-link-active"
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about"
              className="toodecimal-nav-link"
              activeclassname="toodecimal-nav-link-active"
            >
              About Us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/blogs"
              className="toodecimal-nav-link"
              activeclassname="toodecimal-nav-link-active"
            >
              Blog
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="toodecimal-nav-link"
              activeclassname="toodecimal-nav-link-active"
            >
              Get in Touch
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
