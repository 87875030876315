import {
  AgriculutreImg,
  AutomotiveImg,
  AviationImg,
  ConstructionImg,
  DroneImg,
  HeatManagementImg,
  LandscapingImg,
  MiningImg,
  MuncipalityImg,
  PortOperationsImg,
} from "../assets/Home";

const industriesData = [
  {
    title: "Agriculture",
    content:
      "Agricultural and poultry endeavour are heavily influenced by the weather.",
    displayImg: AgriculutreImg,
    bgColor: "#72bf44",
  },
  {
    title: "Automotive",
    content:
      "Automobiles have been an integral part as they are a big contributor to global warming.",
    displayImg: AutomotiveImg,
    bgColor: "#ff6600",
  },
  {
    title: "Aviation",
    content:
      "Due to fog, snow etc. air traffic controllers have to reduce the number of operating aircraft.",
    displayImg: AviationImg,
    bgColor: "#0072f5",
  },
  {
    title: "Construction",
    content:
      "Construction equipment's performance and wear and tear can be influenced by the weather.",
    displayImg: ConstructionImg,
    bgColor: "#70d7c0",
  },
  {
    title: "Drones",
    content:
      "Whether drones are specifically constructed for improving weather forecasting models.",
    displayImg: DroneImg,
    bgColor: "#fbcd4b",
  },
  {
    title: "Heat Management",
    content:
      "Warmer temperatures have the potential to trigger a cascade of other changes around the world.",
    displayImg: HeatManagementImg,
    bgColor: "#32869e",
  },
  {
    title: "Landscaping",
    content:
      "Rain can erode your yard's soil and cause it to deteriorate affecting the plants there.",
    displayImg: LandscapingImg,
    bgColor: "#0a737b",
  },
  {
    title: "Mining",
    content:
      "The weather creates certain unique challenges to safe mining operations.",
    displayImg: MiningImg,
    bgColor: "#fed858",
  },
  {
    title: "Municipalities",
    content:
      "Climate change is causing many shocks and stressors to communities all around the planet.",
    displayImg: MuncipalityImg,
    bgColor: "#70d7c0",
  },
  {
    title: "Port operations",
    content:
      "Extreme weather conditions have a significant financial and time impact on cargo ships and port operations.",
    displayImg: PortOperationsImg,
    bgColor: "#006666",
  },
];

export default industriesData;
