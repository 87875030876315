export const SET_TITLE_FILTER = "SET_TITLE_FILTER";
export const SET_SORT_FILTER = "SET_SORT_FILTER";
export const SET_SEARCH_OPTION = "SET_SEARCH_OPTION";
export const SET_TO_DATE = "SET_TO_DATE";
export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_TITLE = "SET_TITLE";  
export const SET_DATE_FILTER_TOGGLE = "SET_DATE_FILTER_TOGGLE";
export const SET_AUTHOR_FILTER_TOGGLE = "SET_AUTHOR_FILTER_TOGGLE";
export const SET_AUTHOR = "SET_AUTHOR";   
export const SET_SORT_TYPE_FILTER = "SET_SORT_TYPE_FILTER";
 