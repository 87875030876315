import React, { Suspense } from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Satellite,
  CamImg,
  CamImgBg,
  Line,
  Galaxy,
  Sun,
  UpArr,
  GlobeImg,
  Lines,
  BhugoalNode,
} from "../../assets/Home";
import JoinWaitList from "../../components/JoinWaitListCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Globe from "react-globe.gl";
import { COUNTRIES_DATA } from "../../assets/data/countries_data";
import HEX_DATA from "../../assets/data/countries_hex_data.json";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import TypeWriterEffect from "react-typewriter-effect";
import { revealAnimation, hideAnimation } from "../../utils/framerAnimation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import industriesData from "../../constants/industries";

const useMountEffect = (fun) => useEffect(fun, []);

gsap.registerPlugin(ScrollTrigger);
const getRandomCountry = () => {
  return COUNTRIES_DATA[Math.floor(Math.random() * COUNTRIES_DATA.length)];
};
const OtherComponent = React.lazy(() =>
  import("../../components/OtherComponent")
);

const Home = ({ isLoggedIn }) => {
  const globeEl = useRef();
  const [formStatus, setformStatus] = useState(false);

  useEffect(() => {
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 1.9;
    globeEl.current.controls().enableZoom = false;
  }, []);
  const country = getRandomCountry();
  const [selectedCountry, setSelectedCountry] = useState({
    lat: country.latitude,
    lng: country.longitude,
    label: country.name,
  });
  const [hex, setHex] = useState({ features: [] });

  useEffect(() => {
    setHex(HEX_DATA);
  }, []);

  // useEffect(() => {
  //   let interval;

  //   interval = setInterval(() => {
  //     (async () => {
  //       const country = getRandomCountry();
  //       setSelectedCountry({
  //         lat: country.latitude,
  //         lng: country.longitude,
  //         label: country.name,
  //       });
  //     })();
  //   }, 20000);
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // globeEl.current.controls().autoRotate = true;
    // globeEl.current.controls().autoRotateSpeed = 0.2;

    const MAP_CENTER = { lat: 0, lng: 0, altitude: 1.5 };
    globeEl.current.pointOfView(MAP_CENTER, 0);
  }, [globeEl]);

  useEffect(() => {
    const countryLocation = {
      lat: selectedCountry.lat,
      lng: selectedCountry.lng,
      altitude: 1.5,
    };

    globeEl.current.pointOfView(countryLocation, 0);
  }, [selectedCountry]);
  //   const THREE = require('three')

  document.addEventListener("scroll", parallax);
  function parallax(event) {
    this.querySelectorAll(".mouse").forEach((shift) => {
      const position = shift.getAttribute("value");
      const x = (window.innerWidth - event.pageX * position) / 90;
      const y = (window.innerHeight - event.pageY * position) / 90;

      shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
      // console.log( shift.style.transform);
    });
  }

  useEffect(() => {
    gsap.to(".satelite-img", {
      xPercent: -250,
      duration: 2,
      ease: "slow(0.1, 0.1, false)",
      scrollTrigger: {
        trigger: ".satelite-img",
        start: "top center",
        end: "bottom top",
        scrub: true,
      },
    });
  }, []);

  const animationcontrol = useAnimation();
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  // const [viewText2, setViewText2] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setViewText2(true);
  //   }, 20000);
  // });

  const [viewText, setViewText] = useState(false);
  const [viewText3, setViewText3] = useState(false);
  const [terminal, setTerminal] = useState(false);
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setViewText(true);
      }, 4100);
    }
  }, [inView]);
  const [viewCont, setViewCont] = useState(false);
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setViewText3(true);
      }, 8800);
    }
  }, [inView]);
  useEffect(() => {
    if (inView) {
      setTerminal(true);
    }
  }, [inView]);
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setViewCont(true);
      }, 6000);
    }
  }, [inView]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    // slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 0,
    arrows: false,
    speed: 2300,
    // adaptiveHeight: true,
    cssEase: "linear",
    centerMode: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const arcsData = [
    {
      startLat: 49.242699159821626,
      startLng: -123.08802832585313,
      endLat: 43.690606260858665,
      endLng: -79.5559059838268,
      color: " white",
    },
    {
      startLat: 51.051997582400794,
      startLng: 9.927061831280204,
      endLat: 48.86165703356597,
      endLng: 2.3413686107306444,
      color: " white",
    },
    {
      startLat: -37.79203513638592,
      startLng: 145.06358168456356,
      endLat: 25.196820027510203,
      endLng: 55.27165710005668,
      color: " white",
    },
    {
      startLat: 9.473678349225894,
      startLng: 8.283055948202923,
      endLat: -8.697550107679294,
      endLng: -53.39419021185211,
      color: " white",
    },
    {
      startLat: 28.7079923708442,
      startLng: 77.11331459926839,
      endLat: 19.115289201550112,
      endLng: 72.90212032320314,
      color: " white",
    },
    {
      startLat: 56.46324770776989,
      startLng: 37.57891919768704,
      endLat: 63.172569724288245,
      endLng: 17.2603883792217,
      color: " white",
    },
    {
      startLat: 65.1171194242206,
      startLng: -19.068751215273476,
      endLat: 65.00034341477735,
      endLng: -150.70733042296817,
      color: " white",
    },
    // {
    //   startLat: 75.007752,
    //   startLng: -97.092877,

    //   endLat: 19.8781136,
    //   endLng: -287.6297982,
    //   color: " white"
    // },
    // {
    //   startLat: 535.007752,
    //   startLng: -97.092877,

    //   endLat: -132.715736,
    //   endLng: -217.161087,
    //   color: " white"
    // },
    // {
    //   startLat: 435.007752,
    //   startLng: -97.092877,

    //   endLat: -132.715736,
    //   endLng: -287.161087,
    //   color: " white"
    // }
  ];

  // useEffect(() => {
  //   let interval;
  //   interval = setInterval(() => {
  //     (
  //       async () => {
  //       const country = getRandomCountry();
  //       setSelectedCountry({
  //         lat: country.latitude,
  //         lng: country.longitude,
  //         label: country.name
  //       });
  //     }
  //     )();
  //   }, 16000); //Every 3 seconds
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  useMountEffect(executeScroll); // Scroll on mount
  const [colorString, setColorString] = useState("#9821be");

  const toggleGlobe = () => {
    setColorString("purple");
  };
  // const [globeWidth, setGlobeWidth] = useState(136)
  // useEffect(()=>{

  //   if(window.innerWidth < 900){
  //     setGlobeWidth(100)
  //   }
  // })
  const myRef2 = document.querySelector(
    ".why-too-decimal-container-follow-lines-heading-bottom-part-content"
  );
  return (
    <ParallaxProvider>
      <Helmet>
        <title>Home | Too Decimal</title>
        <meta
          name="description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        <meta property="og:title" content={"Home | Too Decimal"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            "Too Decimal services provide timely and continuous updates on the climatic changes in your locality along with accurate data insights"
          }
        />
        {/* <meta property="og:image" content={} /> */}
        <meta property="og:url" content={window.location.href.toString()} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        exit={{ y: 20 }}
        transition={{ duration: 1 }}
      >
        <button onClick={() => executeScroll()}>click</button>

        <div className="home-page-wrapper-class">
          <section className="forhome-oneandtwo">
            <div className="home-page-background">
              <div className="home-page-satelite">
                <img
                  className="satelite-img"
                  draggable={false}
                  src={Satellite}
                  alt=""
                />
              </div>
              <div
                className="home-page-globe"
                //  style={{width:`${globeWidth}%`}}
              >
                <Globe
                  //  globeImageUrl
                  //  ={'//unpkg.com/three-globe/example/img/earth-night.jpg'}
                  ref={globeEl}
                  backgroundColor="rgba(0,0,0,0)"
                  showAtmosphere={false}
                  globeImageUrl={GlobeImg}
                  animateIn={true}
                  // labelsData={[selectedCountry]}
                  // labelText={"label"}
                  // labelSize={1.6}
                  // labelColor={useCallback(() => "white", [])}
                  // labelDotRadius={0.4}
                  // labelAltitude={0.05}
                  hexPolygonsData={hex.features}
                  hexPolygonResolution={3}
                  hexPolygonMargin={0.62}
                  pointAltitude="size"
                  pointRadius="radius"
                  hexBinMerge={false}
                  pointColor="color"
                  // animateIn={true}
                  // arcsTransitionDuration={8300}
                  // arcStroke={0.25}
                  // arcColor={"color"}
                  // arcAltitude={0.1}
                  // arcAltitudeAutoScale={0.25}
                  // arcsData={arcsData}
                  // hexAltitude={10}
                  // pointOfView={{ altitude: 3.5 }}
                  // hexPolygonColor={() => "red"}
                  // hexPolygonColor={parakhar => "black"}
                  hexPolygonColor={useCallback(() => "#9821be", [])}
                  // hexTopColor={()=>"red"}
                  //  onHexHover={
                  //    (hexPolygonColor,)
                  //  }
                  // onPointHover={}
                  // onHexHover={
                  //  hoveraction=>globeEl(
                  //   hexPolygonColor(parakhar=>{
                  //     return("red")

                  //  }))
                  // }
                />
              </div>
              <Parallax speed={-17}>
                <div className="home-sun">
                  <img draggable={false} src={Sun} alt="" className="mouse" />
                </div>
              </Parallax>
              <h1>
                Are you ready for the next <span>big</span> thing?
              </h1>
              <div className="scroll-down-text">
                <h1>The possibilites are endless with Too Decimal</h1>
                {/* <img draggable={false } src={ScrollDown} alt="" /> */}
                <div onClick={() => executeScroll()}>
                  <svg
                    width="32"
                    height="84"
                    viewBox="0 0 32 84"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="28.6336"
                      height="53"
                      rx="14.3168"
                      stroke="white"
                      strokeWidth="3"
                    />
                    <line
                      id="scroll-wheel"
                      x1="16"
                      y1="25"
                      x2="16"
                      y2="14"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <path
                      d="M8 69L16 77L24 69"
                      stroke="white"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                {/* <DownSvg/> */}
              </div>
              {/* <div className="home-page-bg-satelite">
          <img draggable={false } className="satelite-img" src={Satellite} alt="" />
        </div> */}
            </div>
            <div className="home-page-section-intro" ref={myRef}>
              <motion.div
                initial={hideAnimation}
                animate={revealAnimation}
                className="home-page-section-intro-text"
              >
                <h1 className="display-none-text">
                  Introducing <span>Bhugoal</span>{" "}
                </h1>
                <a className="display-none-sub" href="">
                  By Too Decimal
                </a>
                <p>
                  We bring to you a combination of unique and user-friendly
                  services,providing accurate and exact location-based weather
                  forecasts so that you can schedule your day accordingly. One
                  should expect no surprises when using our services as we make
                  sure that the weather forecasts provided by us are to the
                  absolute precision, with the help of cutting edge technology
                  and real-time data inputs from the mesh network of the devices
                  created by us, we ensure that there is no space for any error.
                </p>
              </motion.div>

              <div className="home-page-section-intro-img">
                <h1 className="display-after-1200">
                  Introducing <span>Bhugoal</span>{" "}
                </h1>
                <a className="display-after-1200" href="">
                  By Too Decimal
                </a>
                <img
                  draggable={false}
                  src={BhugoalNode}
                  alt="Bhugoal Hardware"
                  style={{ maxHeight: 700, objectFit: "contain" }}
                />
                {/* <div className="home-page-section-intro-img-bg">
                  <img
                    draggable={false}
                    src={CamImgBg}
                    alt="Yellow Background"
                  />
                </div> */}
              </div>
            </div>
          </section>
          <Parallax speed={window.innerWidth < 768 ? 4 : 10}>
            <div className="galaxy-wrapper">
              <div className="galaxy-bacgkground">
                <img src={Galaxy} alt="" value="-5" className="mouse" />
              </div>
            </div>
          </Parallax>
          <Parallax speed={window.innerWidth < 768 ? -4 : -10}>
            <div className="first-join-wait-list">
              <JoinWaitList status={formStatus} setStatus={setformStatus} />
            </div>
          </Parallax>

          <div className="why-too-decimal-section" ref={ref}>
            <div className="why-decimal-svg-line">{/* <HomeSvg/> */}</div>

            <h1 className="why-decimal-heading">
              Why Choose <span> Too Decimal?</span>
            </h1>
            <div className="flex-whydecimal-wrap">
              {/* {inView ? ( */}
              {/* <div className="why-decimal-anim-svg">
                <HomeSvg />
                <HomeSvg2 />
              </div>  */}
              <div
                className="svg-style"
                style={{
                  marginLeft: "-2%",
                }}
              >
                <img src={Lines} alt="" />
              </div>

              <div className="why-choose-too-decimal-container">
                <div className="color-dots">
                  <div className="color-dot1"></div>
                  <div className="color-dot2"></div>
                  <div className="color-dot3"></div>
                </div>
                {terminal ? (
                  <div className="why-too-decimal-section-top-text">
                    <TypeWriterEffect
                      typeSpeed={5}
                      cursorColor="green"
                      hideCursorAfterText={true}
                      scrollArea={false}
                      text="Initiate product development (1.0.2-5) . . ."
                    />
                    <TypeWriterEffect
                      typeSpeed={5}
                      startDelay={1100}
                      cursorColor="green"
                      hideCursorAfterText={true}
                      text="User time requested . . ."
                    />
                    <TypeWriterEffect
                      typeSpeed={5}
                      startDelay={1600}
                      cursorColor="green"
                      hideCursorAfterText={true}
                      text="Processing trigger for product-launch"
                    />
                    <TypeWriterEffect
                      typeSpeed={5}
                      startDelay={2700}
                      cursorColor="green"
                      hideCursorAfterText={true}
                      text="Bhugoal V1 launching soon !!!"
                    />
                  </div>
                ) : null}
                <div className="we-are-just-different-section">
                  {viewText ? (
                    <div
                      // initial={hideAnimation}
                      // animate={revealAnimation}
                      className="second-appearing-part-cmd"
                    >
                      <Suspense fallback={<></>}>
                        <h1>
                          {/* We are just <OtherComponent /> */}
                          We are just &nbsp;<span> different</span>
                        </h1>
                      </Suspense>

                      <p>
                        <span> Bhugoal@root-user: ~$</span>{" "}
                        <TypeWriterEffect
                          typeSpeed={10}
                          cursorColor="green"
                          hideCursorAfterText={true}
                          text=" sudo list differences --mode-tree"
                        />
                      </p>
                    </div>
                  ) : null}
                  {viewCont ? (
                    <div
                      // initial={hideAnimation}
                      // animate={revealAnimation}
                      className="follow-lines-wrapper"
                    >
                      <div className="why-too-decimal-container-follow-lines">
                        <div className="why-too-decimal-container-follow-lines-heading">
                          <img draggable={false} src={Line} alt="" />
                          <h1> Accurate Weather Predictions</h1>
                        </div>
                        <p>
                          The weather displayed is not just based on the average
                          temperature of an area but is accurately calculated
                          and studied using weather patterns and data insights.
                        </p>
                        <div className="why-too-decimal-container-follow-lines-heading">
                          <img draggable={false} src={Line} alt="" />
                          <h1>
                            <span> dir</span>
                          </h1>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="text-under-dir">
                          <div className="why-too-decimal-container-follow-lines-heading">
                            <img draggable={false} src={Line} alt="" />
                            <h1> location-specific Predictions</h1>
                          </div>
                          <p>
                            The user is not kept second-guessing to know the
                            weather at their specific location from a wide range
                            of an area as we provide precise locality weather
                            prediction for the users.
                          </p>
                        </div>
                      </div>
                      <div className="why-too-decimal-container-follow-lines-heading why-too-decimal-container-follow-lines-heading-bottom-part">
                        <img draggable={false} src={Line} alt="" />
                        <h1> Around the clock Predictions</h1>
                      </div>
                      <p className="why-too-decimal-container-follow-lines-heading-bottom-part-content">
                        We have our weather stations that give real-time data
                        with the help of AI throughout the day at any given
                        moment and not just the expected weather ahead.
                      </p>
                      <p className="bottom-why-text">
                        3 points, unlimited opportunities
                      </p>

                      <div className="timeout-text">
                        <span>Bhugoal@root-user: ~$</span>
                        <p>
                          <TypeWriterEffect
                            typeSpeed={10}
                            // startDelay={4500}
                            cursorColor="green"
                            hideCursorAfterText={true}
                            scrollArea={myRef2}
                            text="timeout 1 ping when-will-bhugoal-be-available.com "
                          />
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {viewText3 ? (
                    <div className="last-text-section">
                      <p>
                        ping <span> when-will-bhugoal-be-available.com </span>{" "}
                        78 bytes of data
                      </p>
                      <h3>
                        We are working really hard to make our product available
                        as soon as possible!
                      </h3>
                      <p className="hitEnter">
                        {" "}
                        <span>Bhugoal@root-user: ~$</span>
                      </p>
                    </div>
                  ) : null}
                  {/* </div> */}
                </div>
              </div>
              {/* :null} */}
            </div>
          </div>

          <div className="industries-we-serve-section">
            <div className="industry-we-serve-gradient"></div>
            <div className="industry-section-text">
              <h1>
                Industries we <img draggable={false} src={UpArr} alt="" /> serve
              </h1>
              <p>ARE ABOUT TO</p>
            </div>

            <div className="carousel-new">
              <div className="carousel-track">
                {industriesData.map((industry, index) => {
                  return (
                    <div
                      className="carousel-card"
                      key={index}
                      style={{ backgroundColor: industry.bgColor }}
                    >
                      <div className="carosel-card-img">
                        <div className="bike-img-bg">
                          <img
                            draggable={false}
                            src={industry?.displayImg}
                            alt={industry?.title || "Industry"}
                          />
                        </div>
                        <img
                          draggable={false}
                          src={industry?.displayImg}
                          alt={industry?.title || "Industry"}
                        />
                      </div>
                      <div className="carousel-text">
                        <p>{industry?.content}</p>
                        <h1>{industry?.title}</h1>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="join-wait-list-card-bottom-container">
            <JoinWaitList status={formStatus} setStatus={setformStatus} />
          </div>
        </div>
      </motion.div>
    </ParallaxProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: get(state, "auth.isLoggedIn", false),
  };
};

export default connect(mapStateToProps)(Home);
