import React, { useEffect } from "react";
import { Route,  Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Home from "../pages/Home";
import { get } from "lodash";
import { connect } from "react-redux";
import AboutUs from "../pages/About Us";
import "../index.css";
import Contact from "../pages/Contact";
import BlogLanding from "../pages/BlogLanding";
import BlogOpen from "../pages/BlogOpen";
import BlogAll from "../pages/BlogAll";
import Privacy from "../pages/Privacy";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

const Router = ({ isLoggedIn, selectedBlog }) => {
  const location = useLocation();
useEffect(() => {
  if(location.pathname!=="/blogs/:id"){
    selectedBlog={};
  }},[])
return  (
  <>
    <Header />
      <ScrollToTop>
      <AnimatePresence key={location.pathname} exitBeforeEnter>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* <Switch location={location} key={location.pathname}>
          <Route path="/about" ><AboutUs /></Route>
          <Route path="/contact" ><Contact /></Route>
          <Route path="/blogs" ><BlogLanding /></Route>
          <Route path="/blogs/:id" ><BlogOpen /></Route>
          <Route path="/all-blogs" ><BlogAll /></Route>
          <Route path="/privacy" ><Privacy /></Route>
          </Switch> */}
          <Routes>
            <Route path="/about" element={<AboutUs />} />
            <Route  path="/contact" element={<Contact />} />
            <Route  path="/blogs" element={<BlogLanding />} />
            <Route  path="/blogs/:id" element={<BlogOpen />} />
            <Route  path="/all-blogs" element={<BlogAll />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </motion.div>
        </AnimatePresence>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </ScrollToTop>
    <Footer />
  </>
)};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: get(state, "auth.isLoggedIn", false),
    selectedBlog: get(state, "blogs.selectedBlog", {}),
  };
};

export default connect(mapStateToProps)(Router);
