import {
  FETCH_ALL_BLOGS,
  FETCH_ALL_BLOG_CATEGORIES,
  FETCH_BLOG,
  FETCH_RANDOM_BLOGS,
  FETCH_FEATURED_BLOGS,
  LOGOUT,
} from "../types";

const blogsInitialState = {
  allBlogs: [],
  selectedBlog: {},
  blogCategories: [],
  randomBlogs: [],
  featuredBlogs: [],
  loading: false,
  randomLoader: false,
  featuredLoader: false,
  error: {},
  pages: 0,
};

const blogsReducer = (state = blogsInitialState, action) => {
  switch (action.type) {
    case FETCH_FEATURED_BLOGS.REQUEST:
      return {
        ...state,
        featuredLoader: true,
      }
    case FETCH_FEATURED_BLOGS.SUCCESS:
      return {
        ...state,
        featuredLoader: false,
        featuredBlogs: action.payload,
        error: "",
      }
    case FETCH_FEATURED_BLOGS.FAILURE:
      return {
        ...state,
        featuredLoader: false,
        featuredBlogs: action.payload,
        error: action.payload.message,
      }
      case FETCH_RANDOM_BLOGS.REQUEST:
      return {
        ...state,
        randomLoader: true,
      };
    case FETCH_RANDOM_BLOGS.SUCCESS:
      return {
        ...state,
        randomLoader: false,
        randomBlogs: action.payload.blog,
        error: "",
      };
    case FETCH_RANDOM_BLOGS.FAILURE:
      return {
        ...state,
        randomLoader: false,
        randomBlogs: [],
        error: action.payload.message,
      };
    case FETCH_ALL_BLOGS.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_BLOGS.SUCCESS:
      console.log(action.payload.pages)
      return {
        ...state,
        loading: false,
        allBlogs: action.payload.result,
        pages: action.payload.pages,
      };
    case FETCH_ALL_BLOGS.FAILURE:
      return {
        ...state,
        loading: false,
        allBlogs: [],
        error: action.payload.message,
      };
    case FETCH_BLOG.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BLOG.SUCCESS:
      return {
        ...state,
        loading: false,
        selectedBlog: action.payload,
      };
    case FETCH_BLOG.FAILURE:
      return {
        ...state,
        loading: false,
        selectedBlog: {},
        error: action.payload.message,
      };
    case FETCH_ALL_BLOG_CATEGORIES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_BLOG_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        blogCategories: action.payload.result,
      };
    case FETCH_ALL_BLOG_CATEGORIES.FAILURE:
      return {
        ...state,
        loading: false,
        selectedBlog: {},
        error: action.payload.message,
      };
    case LOGOUT.REQUEST:
      return blogsInitialState;
    default:
      return state;
  }
};

export default blogsReducer;
