import React, { useState } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { createContactFormRequest } from "../../store/actions/contactForm";
import axios from "axios";
import { apiURL } from "../../constants";
import TypeWriterEffect from "react-typewriter-effect";
import { motion } from "framer-motion";
import {
  appearAnimation,
  formHideAnimation,
} from "../../utils/framerAnimation";
import ReactTooltip from "react-tooltip";
import { BsArrowReturnLeft } from "react-icons/bs";

export default function ContactForm({ data }) {
  const dispatch = useDispatch();
  let formSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email("Invalid Email").required(),
    message: yup.string(),
  });

  const [showInput, setShowInput] = useState({
    name: true,
    nameProcess: false,
    email: false,
    emailProcess: false,
    message: false,
    messageProcess: false,
  });
  const successText = document.getElementById("displayText");

  const formDisplay = () => {
    const form = document.getElementById("contactUSForm");
    form.style.display = "none";
    successText.style.display = "flex ";
    successText.style.alignItems = "center";
    console.log("called");
    window.addEventListener('keydown', (e) => {
      if (e.key === "Enter") {
        restartHandler();
      }
    }, { once:true});
  };
  axios.defaults.baseURL = apiURL;
  const submitHandler = (values, { setSubmitting, resetForm }) => {
    let formData = {
      name: values.name,
      email: values.email,
      message: values.message,
    };
    axios.defaults.baseURL = apiURL;
    dispatch(createContactFormRequest(formData));
    console.log(formData);
    axios
      .post("/api/v1/contactForm/", formData)
      .then((response) => {
        formDisplay();
      })
      .catch((err) => {});
    setSubmitting(false);
    resetForm({
      name: "",
      email: "",
      message: "",
    });
  };

  const KeyPressHandler = (e, inputBox) => {
    e.preventDefault();
    setShowInput({ ...showInput, [inputBox]: true }); //Check Changes
    // if (inputBox === "email") {
    //   setShowInput({ ...showInput, nameProcess: true });
    //   setTimeout(() => {
    //     e.preventDefault();
    //     setShowInput({ ...showInput, nameProcess: true, email: true });
    //   }, 2000);
    // } else if (inputBox === "message") {
    //   setShowInput({
    //     ...showInput,
    //     nameProcess: true,
    //     email: true,
    //     emailProcess: true,
    //   });
    //   setTimeout(() => {
    //     setShowInput({
    //       ...showInput,
    //       nameProcess: true,
    //       email: true,
    //       emailProcess: true,
    //       message: true,
    //     });
    //   }, 2000);
    // }
  };
 
  const restartHandler = () => {
  setShowInput({
      name: true,
      nameProcess: false,
      email: false,
      emailProcess: false,
      message: false,
      messageProcess: false,
    });
    const form = document.getElementById("contactUSForm");
    form.style.display = "unset";
     successText.style.display = "none";
   
  }
  return (
    <div className={`contactForm `}>
      <div className="top">
        <p>Assets/.Internal/Bhugoal/get-in-touch</p>
        <button style={{ fontSize: 20 }}>&#10006;</button>
      </div>
      <div className="body">
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          
          validationSchema={formSchema}
          onSubmit={submitHandler}
          // onReset={resetHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form autoComplete="off" id="contactUSForm">
              <div className="inputBox">
                <i className="tooltip-icon">
                  <BsArrowReturnLeft data-tip data-for="nameTip" />
                </i>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  onBlur={handleBlur}
                  value={values.name}
                  onKeyPress={(e) => {
                     
                      if (e.key === "Enter" && !errors.name && values.name!=="")
                          KeyPressHandler(e, "email");
                    
                  }}
                  autoFocus={showInput.name && !showInput.email}
                  //  if (!autoFocus) {
                  //           console.log("");                     
                  //  }
                  onChange={handleChange}
                  autoComplete="off"/>
                <ReactTooltip
                  id="nameTip"
                  place="right"
                  effect="solid"
                  textColor="#030303"
                  backgroundColor="#FFFDFD"
                >
                  Enter
                </ReactTooltip>

                <label htmlFor="name">
                  <span>Bhugoal@root-user: ~$ </span>
                  <TypeWriterEffect
                    typeSpeed={20}
                    cursorColor="white"
                    hideCursorAfterText={true}
                    text="sudo fetch name --new-user"
                  />
                </label>
              </div>
              {errors.name && touched.name && (
                <div className="input-feedback text-danger">
                  <p
                    style={{
                      marginTop: "5px",
                      marginLeft: "12px",
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {errors.name}
                  </p>
                </div>
              )}

              {showInput.email && (
                  <motion.div
                    className="inputBox"
                    initial={formHideAnimation}
                    animate={appearAnimation}
                  >
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !errors.email && values.email!=="")
                          KeyPressHandler(e, "message");
                      }}
                      autoFocus={showInput.email && !showInput.message}
                      autoComplete="off"
                    />
                    <ReactTooltip
                      id="emailTip"
                      place="right"
                      effect="solid"
                      textColor="#030303"
                      backgroundColor="#FFFDFD"
                    >
                      Enter
                    </ReactTooltip>

                    <label htmlFor="email">
                      <span>Bhugoal@root-user: ~$</span>
                      <TypeWriterEffect
                        typeSpeed={20}
                        cursorColor="white"
                        hideCursorAfterText={true}
                        text="sudo fetch email --new-user"
                      />
                    </label>
                    <i className="tooltip-icon">
                    <BsArrowReturnLeft data-tip data-for="emailTip" />
                  </i>
                  </motion.div>
                 

              )}
              {((errors.email && touched.email) && (!errors.name || !touched.name)) && (
                <div className="input-feedback text-danger">
                  <p
                    style={{
                      marginTop: "5px",
                      marginLeft: "12px",
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    {errors.email}
                  </p>
                </div>
              )}

              {showInput.message && (
     
                  <motion.div
                    className="inputBox"
                    initial={formHideAnimation}
                    animate={appearAnimation}
                  >
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Enter your message . . ."
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.message}
                      disabled={isSubmitting}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") handleSubmit();
                      }}
                      autoFocus={showInput.message}
                    />
                    {errors.message && touched.message && (
                      <div className="input-feedback text-danger">
                        {errors.message}
                      </div>
                    )}
                    <label htmlFor="message">
                      <span>Bhugoal@root-user: ~$</span>
                      <TypeWriterEffect
                        typeSpeed={20}
                        cursorColor="white"
                        hideCursorAfterText={true}
                        text="sudo fetch message --new-user"
                      />
                  </label>
                  <i className="tooltip-text-icon">
                    <BsArrowReturnLeft data-tip data-for="textTip" />
                  </i>
                  <ReactTooltip
                    id="textTip"
                    place="right"
                    effect="solid"
                    textColor="#030303"
                    backgroundColor="#FFFDFD"
                  >
                    Enter
                  </ReactTooltip>
                  </motion.div>
               
         
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                onSubmit={submitHandler}
              >
                {showInput.message && (
                  // <>
                  // <motion.p
                  // 	style={{ margin: "20px 0 16px" , fontSize: "14px"}}
                  // 	animate={blinkLoop}
                  // >
                  // 	Press Enter to transmit the message
                  // </motion.p>
                  <TypeWriterEffect
                    typeSpeed={20}
                    cursorColor="white"
                    hideCursorAfterText={true}
                    textStyle={{ fontSize: "14px !important" }}
                    text="Press Enter to transmit the message"
                  ></TypeWriterEffect>
                  // </>
                )}
              </button>
            </Form>
          )}
        </Formik>
          <motion.p id="displayText" style={{ margin: "20px 0 16px" }}>
          Bhugoal@root-user: ~$
          <TypeWriterEffect
            typeSpeed={20}
            cursorColor="white"
            hideCursorAfterText={true}
            text="Program Complete. We'll contact you soon. Press Restart to submit again"
          ></TypeWriterEffect>
          <span className="blinking-cursor">|</span>
          </motion.p>    
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
      </div>
    </div>
  );
}