import { CREATE_CONTACT_FORM } from "../types/contactForm";

export const createContactFormRequest = (payload) => ({
  type: CREATE_CONTACT_FORM.REQUEST,
  payload,
});

export const createContactFormSuccess = (payload) => ({
  type: CREATE_CONTACT_FORM.SUCCESS,
  payload,
});

export const createContactFormFailure = (payload) => ({
  type: CREATE_CONTACT_FORM.FAILURE,
  payload,
});