import React from "react";
import { useInView } from "react-intersection-observer";
// import FeatureImg from "../../assets/images/blogOpen/Rectangle 397.png";
// import FeatureImg2 from "../../assets/images/blogOpen/Rectangle 399.png";
// import BlogOpenArrow2 from "../../assets/images/blogOpen/Right Arrow.svg";
import { get } from "lodash";
import moment from "moment";
import rect14 from "../../assets/images/bloglanding/Rectangle 14 (1).png";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

export default function FeatureCardAlternate({ featuredData, index }) {
  const animationcontrol = useAnimation();
  const { ref, inView, entry } = useInView();
  if (inView) {
    animationcontrol.start({
      opacity: [0.2, 0.5, 1],
      zIndex: 2,
      x: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    });
  }

  return (
    <div ref={ref} className="blog-landing-feature-section1 Alternate">
      {/* <motion.div
        initial={{ opacity: [0, 0, 0] }}
        animate={animationcontrol}
        exit={{ opacity: [1, 1, 1] }}
        
      > */}
      <div className="feautred-articles-blogopen-cardimg blog-landing-feature-section1img">
        <img
          src={get(featuredData, "headerImage", { rect14 })}
          alt="Blog Feature Img"
        />
      </div>
      <div className="feautred-articles-blogopen-cardimg-after-500">
        <img
          src={get(featuredData, "headerImage", { rect14 })}
          alt="Blog Feature Img"
        />
      </div>
      <div className="feautred-articles-blogopen-card-info blog-landing-feature-section1-info">
        <button>{get(featuredData, "keywords[0]", "[]")}</button>
        <h2>
          {get(
            featuredData,
            "title",
            "This will be some random heading for the article."
          )}
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: `${
              get(featuredData, "content", "<p>Content Not Found</p>")
                .substring(0, 200)
                .replace(/<\/?[^>]+(>|$)/g, "") + "..."
            }`,
          }}
          className="blogContent  blog-landing-feature-section1-info-ptag"
        ></p>
        <div className="author-name-text-blog">
          <h3>{get(featuredData, "author", "John Doe")}</h3>
          <p className="article-read-time">
            Read time:{" "}
            {moment(`${get(featuredData, "timestamp", "3 minutes")}`).format(
              "mm"
            )}{" "}
            minutes
          </p>
        </div>
        <p className="article-read-time">
          {moment(
            `${
              get(featuredData, "timestamp", "26th-September-2020")
                ? get(featuredData, "timestamp", "26th-September-2020")
                : get(featuredData, "createdAt", "26th-September-2020")
            }`
          ).format("ll")}
        </p>
        <div className="read-more-blog-open">
          <Link to={`/blogs/` + get(featuredData, "blogId", "id")}>
            Read Article
          </Link>
          {/* <img src={BlogOpenArrow2} alt="" /> */}
        </div>
      </div>
      {/* </motion.div> */}
    </div>
  );
}
