import BlogImg2 from "../../assets/images/bloglanding/image 2 (3).png";
// import BlogImg2 from '../../assets/images/bloglanding/image 2 (3).png'
import React from "react";
import { get } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
const BlogOpenCard = ({ blogOpen, index }) => {
  const truncate = (str) => {
    return str.length > 100 ? str.substr(0, 100) + "..." : str;
  };

  let blogContent = document.getElementsByClassName("blogContent");
  truncate(blogContent);
  return (
    <div className="blog-open-card1">
      <div className="blog-open-card-img">
        <Link to={`/blogs/` + get(blogOpen, "blogId", "id")}>
          <img
            src={get(blogOpen, "headerImage", { BlogImg2 })}
            alt="BlogImage"
          />
        </Link>
      </div>
      <div className="blog-open-card-info">
        <h3>
          {get(blogOpen, "title", "The heading of the blog will be here.")}
        </h3>

        <div className="blog-open-card-info-namedate">
          {/* <p>{truncate(`${get(blogOpen, "content", "<p>Content not found</p>")}`)}</p> */}
          {/* <p>{get(blogOpen,"createdAt")}</p> */}
          <p>
            {moment(
              `${
                get(blogOpen, "timestamp", "26th-September-2020")
                  ? get(blogOpen, "timestamp", "26th-September-2020")
                  : get(blogOpen, "createdAt", "26th-September-2020")
              }`
            ).format("ll")}
          </p>
          {/* <p>{moment(`get(blogOpen,"createdAt","26th-September-2020")}`).format("ll")}</p> */}
          <h6 className="authorButton">
            <p>
              By {get(blogOpen, "author", " Souha.H")}
              {/* <img src={BlogImg2} alt="" /> */}
            </p>{" "}
          </h6>
        </div>
        <Link to={`/blogs/` + get(blogOpen, "blogId", "id")}>Read more</Link>
      </div>
    </div>
  );
};

export default BlogOpenCard;
